import React, { useContext, useState } from 'react';
import GlobalContext from '../../GlobalContext';
import PropTypes from 'prop-types';
import Card from '../Card';
import { Button } from 'semantic-ui-react';
import { toast } from 'react-toastify';

import { linkAccountsByCode } from '../../services/api';
import { containerCSS, infoInputContainerCSS } from './style';
import { professorCodeCSS } from '../../containers/StudentHome/InfoCard/style';
import {
  LABELS_BY_RELATION,
  RELATIONSHIP_REQUEST_ACTIONS,
} from '../../constants';

const getRequestAction = (isNationalTestStudent) => {
  return isNationalTestStudent
    ? RELATIONSHIP_REQUEST_ACTIONS.student_to_teacher
    : RELATIONSHIP_REQUEST_ACTIONS.sendNotification;
};

const RelateUsersInput = ({ currentUser, isNationalTestStudent }) => {
  const { dispatches } = useContext(GlobalContext);
  const [code, setCode] = useState('');
  const inputDescription = !isNationalTestStudent
    ? LABELS_BY_RELATION[currentUser.provider]
    : LABELS_BY_RELATION['studentToTeacher'];
  const requestAction = getRequestAction(isNationalTestStudent);
  const isStudent = currentUser.type === 'student';

  const handleInput = (setter) => ({ target }) => setter(target.value);

  const errorHandler = (statusCode) => {
    if (statusCode === 409) {
      return toast.error('Este código no es válido o ya fue agregado.');
    }
    return toast.error(
      'Ha ocurrido un error con el servicio, intenta más tarde.'
    );
  };

  const handleSubmit = async () => {
    const result = await linkAccountsByCode({
      action: requestAction,
      token: currentUser.token,
      code,
      provider: currentUser.provider,
    });

    if (result.status === 200) {
      const { data } = result;
      isStudent
        ? dispatches.addTutorListStudent(data)
        : dispatches.updateStudentListTutor(data);
      toast.success('Operación exitosa.');
      setCode('');
    } else {
      errorHandler(result.status);
    }
  };

  return (
    <div css={professorCodeCSS}>
      <Card cardContainerCSS={containerCSS}>
        <span>{inputDescription}</span>
        <div css={infoInputContainerCSS}>
          <input
            onChange={handleInput(setCode)}
            placeholder="Código"
            value={code}
          />
          <Button content="Agregar" onClick={() => handleSubmit()} />
        </div>
      </Card>
    </div>
  );
};

RelateUsersInput.propTypes = {
  currentUser: PropTypes.object,
  isNationalTestStudent: PropTypes.bool,
};

RelateUsersInput.defaultProps = {
  currentUser: undefined,
  isNationalTestStudent: false,
};

export default RelateUsersInput;
