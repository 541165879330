import { css } from '@emotion/core';

export const resetPasswordCardCSS = css`
  width: 380px;
  height: 297px;
  border-radius: 10px;
`;

export const resetPasswordCardTitleCSS = css`
  display: flex;
  justify-content: center;
  margin-top: 50px;
`;

export const resetPasswordInputCSS = css`
  display: flex;
  justify-content: center;
  margin-top: 38px;

  .ui.focus input {
    padding: 8px;
    border-color: #ccc;
    width: 320px;
    height: 34px;
  }
`;

export const resetPasswordButtonCSS = css`
  display: flex;
  justify-content: center;
  margin-top: 30px;

  .ui.button {
    border-radius: 5px;
    padding: 10px;
    width: 205px;
    background-color: #fc8171;
    color: #fff;
    display: block;

    &:disabled {
      background-color: rgb(204, 204, 204);
    }
  }
`;

export const resetPasswordLinkCSS = css`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export const resetPasswordContainerCSS = css`
  flex-direction: column;
  display: flex;
  align-items: center;
  text-align: center;
`;

export const resetPasswordTitleCSS = css`
  font-size: 35px;
  margin-top: 50px;
  margin-bottom: 30px;
  color: #fff;
`;
