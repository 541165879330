import React from 'react';
import { Segment, Grid, Container, Button } from 'semantic-ui-react';
import { footerCSS, footerLinksCSS } from './style';

const Footer = () => (
  <footer css={footerCSS}>
    <Segment vertical>
      <Container>
        <Grid textAlign="center" columns={2}>
          <Grid.Row>
            <Grid.Column>
              © Copyright Allrights Reserved by <b>PiTech</b>
            </Grid.Column>
            <Grid.Column>
              <div>
                <Button
                  size="big"
                  circular
                  href="https://www.facebook.com/okusrd/"
                  icon="facebook f"
                  css={footerLinksCSS}
                />
                <Button
                  size="big"
                  circular
                  icon="instagram"
                  href="https://www.instagram.com/okusrd/"
                  css={footerLinksCSS}
                />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  </footer>
);

export default Footer;
