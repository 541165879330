import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { calculateCharge, formatCurrencyValue } from './utils';
import {
  modalTitleCSS,
  modalResultContainerCSS,
  modalResultTitleCSS,
  modalResultItemsCSS,
} from './style';

import ShoppingCartPayPalButton from './ShoppingCartPayPalButton';
import ShoppingCartItems from './ShoppingCartItems';
import ModalContainer from '../../../components/ModalContainer';
import GlobalContext from '../../../GlobalContext';

const ShoppingCartModal = ({ open, setClose, students }) => {
  const {
    state: { user, shoppingCart, license },
    dispatches,
  } = useContext(GlobalContext);
  const currentUser = { token: user.token, code: user.code, students };
  const [charge, setCharge] = useState(0);
  const [enableModalClose, setEnableModalClose] = useState(true);

  useEffect(() => {
    const totalCharge = calculateCharge(shoppingCart);
    setCharge(totalCharge);
  }, [shoppingCart.length]);

  const removeHandler = (index) => {
    const studentArray = [...shoppingCart];
    studentArray.splice(index, 1);
    dispatches.deleteShoppingItem('shopping-cart', index, studentArray);
  };

  const afterShoppingHandler = () => {
    const studentsIndex = shoppingCart.map(({ globalIndex }) => globalIndex);
    dispatches.updateLicenseStatusByIndex(studentsIndex);
    dispatches.cleanShoppingCart('shopping-cart', currentUser.code);
    setClose(false);
  };

  const modalOptions = {
    title: {
      content: 'Carrito de compras',
    },
    options: {
      open,
      size: 'tiny',
      closeIcon: true,
      onClose: () => setClose(false),
      className: !enableModalClose ? 'disable-close' : '',
      closeOnDimmerClick: enableModalClose,
      closeOnEscape: enableModalClose,
    },
    actions: (
      <ShoppingCartPayPalButton
        shoppingCart={shoppingCart}
        licenses={license}
        currentUser={currentUser}
        onPaymentFinish={() => afterShoppingHandler()}
        enableModalClose={(enable) => setEnableModalClose(enable)}
      />
    ),
  };

  return (
    <ModalContainer {...modalOptions}>
      <div>
        {shoppingCart.length ? (
          <ShoppingCartItems items={shoppingCart} onRemove={removeHandler} />
        ) : (
          <h2 css={modalTitleCSS}>Tu carrito está vacio</h2>
        )}
      </div>
      <div css={modalResultContainerCSS}>
        <h3 css={modalResultTitleCSS}>Total:</h3>
        <div css={modalResultItemsCSS}>{formatCurrencyValue(charge)}</div>
      </div>
    </ModalContainer>
  );
};

ShoppingCartModal.propTypes = {
  open: PropTypes.bool,
  setClose: PropTypes.func,
  students: PropTypes.array,
};

ShoppingCartModal.defaultProps = {
  open: false,
  setClose: () => {},
  students: [],
};

export default ShoppingCartModal;
