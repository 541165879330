import { css } from '@emotion/core';

export const formInputContainerCSS = css`
  display: flex;
  flex-direction: column;
  padding: 0px 25px;
`;

export const formInputCSS = css`
  &.ui.input {
    margin-bottom: 15px;
  }

  &.ui.input input {
    border: rgba(0, 169, 112, 0.5) solid 1px;
    border-radius: 8px;
    color: rgba(82, 100, 93, 0.8);
    font-family: 'Proxima Nova Regular';
    font-size: 16px;
    height: 50%;
    outline: none;

    &:focus {
      border: rgba(0, 169, 112, 0.5) solid 1px;
      border-radius: 8px;
    }

    &:disabled {
      background-color: rgba(100, 100, 100, 0.4);
      border: rgba(100, 100, 100, 0.4) solid 1px;
      color: rgba(0, 0, 0, 0.87);
    }
  }
`;

export const formButtonContainerCSS = css`
  margin-top: 26px;

  & .ui.button {
    background-color: rgb(251, 110, 91);
    border-radius: 18px;
    color: white;
    cursor: pointer;
    font-family: 'Proxima Nova Regular', serif;
    font-size: 16px;
    outline: none;
    padding: 10px 28px;
  }
`;

export const validationCSS = css`
  display: flex;
  flex-direction: column;
  padding: 0px 25px;

  i {
    position: relative;
    top: -31px;
    left: 355px;
    color: #fbbd08;
    font-size: 20px;
  }
`;

export const popupContainerCSS = {
  left: '8px',
  right: '0',
  top: '0px',
};

export const containerFormCSS = css`
  &.ui.form div label {
    align-items: flex-start;
    color: rgb(82, 100, 93);
    display: flex;
    flex-direction: column;
    font-family: 'Proxima Nova Regular';
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 5px;
    width: 100%;
  }

  & div.field div[class*='validationCSS'] input[class*='formInputCSS'] {
    border: rgba(0, 169, 112, 0.5) solid 1px;
    border-radius: 8px;
    font-family: 'Proxima Nova Regular';
    font-size: 16px;
    color: rgba(82, 100, 93, 0.8);
    height: 100%;
    outline: none;
  }
`;
