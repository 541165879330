import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import BounceLoader from 'react-spinners/BounceLoader';
import loadingContainer from './style';

import GlobalContext from '../../GlobalContext';

const Loading = ({ overrideCSS, children }) => {
  const {
    state: { loading },
  } = useContext(GlobalContext);

  return (
    <div>
      {loading ? (
        <div css={loadingContainer}>
          <BounceLoader
            css={overrideCSS}
            size={150}
            color={'#00A36C'}
            loading={loading}
          />
        </div>
      ) : (
        children
      )}
    </div>
  );
};

Loading.defaultProps = {
  loading: false,
  overrideCSS: {},
  children: undefined,
};

Loading.propTypes = {
  loading: PropTypes.bool,
  overrideCSS: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
};

export default Loading;
