import { post } from 'axios';
const { REACT_APP_BASE_URL } = process.env;

export const addUser = async (provider, toSend) => {
  const request = `${REACT_APP_BASE_URL}/api/backoffice/v2/${provider}`;

  try {
    const result = await post(
      request,
      {
        ...toSend,
      },
      {
        headers: { Accept: 'application/json' },
      }
    );
    return result;
  } catch (e) {
    return e.response;
  }
};
