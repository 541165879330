import React from 'react';
import logo from './img/logo.png';
import { Container } from 'semantic-ui-react';
import {
  headerPage,
  containerPage,
  containerImgTitle,
  headerPageImg,
} from './style';

import Navigation from './Navigation';

const Header = () => {
  return (
    <div css={headerPage}>
      <Container css={containerPage}>
        <div css={containerImgTitle}>
          <a href="https://okus.com.do/">
            <img css={headerPageImg} alt="Logo de Okus App" src={logo} />
          </a>
        </div>
        <Navigation />
      </Container>
    </div>
  );
};

export default Header;
