import React, { useContext, useEffect, useState } from 'react';
import { Dropdown, Icon } from 'semantic-ui-react';
import {
  noNewNotificationsCSS,
  notificationAlertCSS,
  notificationCenterCSS,
  viewedNotificationCSS,
  notificationButtonCSS,
} from './style';

import { getNotifications } from '../../../../../services/api/getNotifications.js';
import {
  formatNotifications,
  getRespondedRequests,
} from '../../../../../GlobalContext/notificationsFormatter.js';
import GlobalContext from '../../../../../GlobalContext';
import NotificationMenu from './NotificationMenu/index';

const NotificationCenter = () => {
  const {
    state: {
      user: { provider, token },
      notificationData: { notifications, unReadNotifications },
    },
    dispatches,
  } = useContext(GlobalContext);
  const [initialLoad, setInitialLoad] = useState(true);
  const [clockUpdate, setClockUpdate] = useState(false);
  const [viewedNotification, setViewedNotification] = useState(false);
  const [open, setOpen] = useState(false);
  const userAgent = navigator.userAgent.toLowerCase();

  const isMobile = window.screen.width <= 767;
  const isSafariOrFireFox =
    (userAgent.indexOf('macintosh') > -1 &&
      userAgent.indexOf('version') > -1) ||
    userAgent.indexOf('firefox') > -1;

  useEffect(() => {
    if (open && unReadNotifications) {
      dispatches.setAllNotificationsRead();
    }
  }, [unReadNotifications]);

  useEffect(() => {
    let timer;
    const updateEvery = (miliseconds) => {
      setTimeout(() => {
        setClockUpdate(!clockUpdate);
      }, miliseconds);
    };

    Promise.all([pullNotifications(), updateEvery(30000)]).then((values) => {
      const [updatedNotificationData, updatedNotifications] = [
        values[0],
        values[0].notifications,
      ];
      const relatedUsers = newRelatedUsers(updatedNotifications);
      dispatches.updateNotificationList(updatedNotificationData, initialLoad);
      relatedUsers.length && dispatches.updateRelationStatus(relatedUsers);
      setInitialLoad(false);
      timer = values[1];
    });
    return () => clearTimeout(timer);
  }, [clockUpdate]);

  const notificationIconStyle = (
    <button
      onBlur={(event) =>
        (!isMobile || !isSafariOrFireFox) && handleViewNotification(event)
      }
      css={notificationButtonCSS}
    >
      <Icon.Group onClick={() => setOpen(!open)}>
        <Icon name="bell outline" />
        <Icon css={viewedNotificationCSS(!unReadNotifications)} name="circle" />
        <Icon
          className="newAlert"
          css={notificationAlertCSS(unReadNotifications || viewedNotification)}
        />
      </Icon.Group>
    </button>
  );

  const pullNotifications = async () => {
    const data = await getNotifications(token, provider, initialLoad);
    return formatNotifications(data);
  };

  const newRelatedUsers = (newNotifications) => {
    const newRelatedUsers = getRespondedRequests(newNotifications);
    return newRelatedUsers;
  };

  const handleNotificationCenter = async () => {
    unReadNotifications && dispatches.setAllNotificationsRead();
    setViewedNotification(true);
  };

  const handleViewNotification = (event) => {
    const bellIcon = event.target;

    // Waiting for document.activeElement to get its actual value.
    setTimeout(() => {
      const isInsideModal = bellIcon.parentNode.contains(
        document.activeElement
      );

      if (isInsideModal) {
        bellIcon.focus();
      } else {
        setOpen(false);
      }
    }, 50);
  };

  return (
    <Dropdown
      button
      css={notificationCenterCSS}
      floating
      icon={notificationIconStyle}
      onClick={() => handleNotificationCenter()}
      onBlur={(event) =>
        (isSafariOrFireFox || isMobile) && handleViewNotification(event)
      }
      scrolling
      open={open}
    >
      <Dropdown.Menu open={open}>
        {notifications.length ? (
          <NotificationMenu />
        ) : (
          <div css={noNewNotificationsCSS}>
            <Dropdown.Item description="No tienes notificaciones nuevas" />
          </div>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NotificationCenter;
