import React from 'react';
import { buttonBuyLicenseCSS } from './style';
import { PropTypes } from 'prop-types';
import { BUTTON_STATE } from './BUTTON_STATE';

const BuyLicenseButton = ({
  hasLicense,
  isInShoppingCart,
  isPendingPurchase,
  onClick,
}) => {
  const buttonState =
    hasLicense && !isInShoppingCart
      ? BUTTON_STATE.hasLicense
      : !hasLicense && isInShoppingCart
      ? BUTTON_STATE.isInShoppingCar
      : isPendingPurchase
      ? BUTTON_STATE.isPendingPurchase
      : BUTTON_STATE.noHasLicense;

  const isEnableColor = !hasLicense && !isInShoppingCart && !isPendingPurchase;
  const hasLicenseColor = hasLicense;

  return (
    <div css={buttonBuyLicenseCSS(isEnableColor, hasLicenseColor)}>
      <button onClick={onClick} disabled={buttonState.disable}>
        {buttonState.label}
      </button>
    </div>
  );
};

BuyLicenseButton.defaultProps = {
  hasLicense: false,
  isInShoppingCart: false,
  isPendingPurchase: false,
  onClick: () => {},
};

BuyLicenseButton.propTypes = {
  hasLicense: PropTypes.bool,
  isInShoppingCart: PropTypes.bool,
  isPendingPurchase: PropTypes.bool,
  onClick: PropTypes.func,
};

export default BuyLicenseButton;
