import { css } from '@emotion/core';

const getNewNotificationIcon = (notificationAmount) => {
  const [newNotifications, noNewNotifications] = [
    css`
      &:before {
        content: '${notificationAmount > 99 ? '99+' : notificationAmount}';
        font-size: ${notificationAmount > 99 ? '0.8em' : '1em'};
        font-family: 'Proxima Nova Regular';
      }
    `,
    css`
      visibility: hidden;
    `,
  ];

  return notificationAmount ? newNotifications : noNewNotifications;
};

export const noNewNotificationsCSS = css`
  height: 75px;
  padding: 30px;

  .description {
    color: #52645d;
  }
`;

export const notificationAlertCSS = (newNotifications) =>
  getNewNotificationIcon(newNotifications);

export const notificationCenterCSS = css`
  &.ui.button {
    align-self: center;
    background: none;
    margin-top: 3px;
    padding: unset;

    &.active:active,
    &.active:hover {
      background: none;
    }

    & > .icon:not(.button) {
      opacity: 1;
    }
  }

  i.icons {
    margin-bottom: 3px;
  }

  i.icon {
    background-color: transparent;
    color: white;
    font-size: 1.7em;
  }

  i.icon.circle {
    background-color: transparent;
    color: #fb6e5b;
    font-size: 1.6em;
    top: -8%;
    left: 75%;
  }

  i.icon.newAlert {
    background-color: transparent;
    color: white;
    font-size: 1em;
    left: 75%;
    top: -3px;
  }

  &.ui.dropdown > .menu {
    box-shadow: none;
    font-family: 'Proxima Nova Semibold';
    font-size: 17px;
    font-weight: 400;
    height: auto;
  }

  @media only screen and (max-device-width: 767px) {
    &.ui.button {
      padding: 0 0 0 10px;
    }

    i.icons {
      margin: unset;
      padding-right: 10px;
    }

    &.ui.dropdown > .menu {
      font-size: 15px;
      left: auto;
      right: 0;
    }
  }

  @media only screen and (max-device-width: 1620px) and (min-width: 767px) {
    &.ui.dropdown > .menu {
      left: auto;
      right: 15px;
    }
  }
`;

export const viewedNotificationCSS = (viewed) => css`
  visibility: ${viewed ? 'hidden' : 'unset'};
`;

export const notificationButtonCSS = css`
  background: none;
  border: none;

  &:focus {
    outline: none;
  }

  .bell {
    cursor: pointer;
  }
`;
