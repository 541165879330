import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Container, Icon } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { updateAssociateProfile, updateProfile } from '../../services/api';
import {
  CardContainerCSS,
  EditSectionCSS,
  IconBorderCSS,
  ProfileTitleCSS,
  UserNameCSS,
  changePasswordButton,
} from './style';
import avatarIMG from '../../assets/img/imgDefault.jpg';
import { ENTITIES } from './entities';

import GlobalContext from '../../GlobalContext';
import Card from '../Card';
import ViewContainer from './ViewContainer';
import FormContainer from './FormContainer';
import ModalContainer from '../ModalContainer';
import ChangePassword from './ChangePasword';

const ProfileCard = ({ userProfile, title, singleStudent }) => {
  const [editMode, setEditMode] = useState(false);
  const [showPasswordForm, setPasswordForm] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { dispatches } = useContext(GlobalContext);
  const { firstname, lastname, type, provider, has_social } = userProfile;

  const modalActions = () => (
    <>
      <button className="ui button" onClick={() => setShowModal(false)}>
        Cancelar
      </button>
      <button
        className="ui primary button"
        onClick={() => {
          setShowModal(false);
          setEditMode(false);
        }}
      >
        Ok
      </button>
    </>
  );

  const handleProfileUpdate = async ({ firstname, lastname, phone }) => {
    const result = await updateProfile(
      provider,
      {
        firstname,
        lastname,
        phone,
      },
      userProfile.token
    );

    if (result.status === 200) {
      dispatches.updateUser({
        ...userProfile,
        firstname,
        lastname,
        phone,
      });
      setEditMode(false);
    } else {
      toast(
        'Ha ocurrido un error en el servicio, por favor intente más tarde.'
      );
    }
  };

  const handlerSingleStudentUpdate = async ({ firstname, lastname, phone }) => {
    const result = await updateAssociateProfile(
      singleStudent.token,
      userProfile.code,
      {
        firstname,
        lastname,
        phone,
      }
    );

    if (result.status === 200) {
      await dispatches.updateSingleStudent(
        { ...userProfile, firstname, lastname, phone },
        singleStudent.index
      );
      setEditMode(false);
    } else {
      toast.error(
        'Ha ocurrido un error en el servicio, por favor intente más tarde.'
      );
    }
  };

  return (
    <>
      <Card
        title={
          <>
            <h1>{title}</h1>
            {!editMode ? (
              <button onClick={() => setEditMode(true)} css={EditSectionCSS}>
                <>
                  <span>Editar</span>
                  <div css={IconBorderCSS}>
                    <Icon name="pencil" />
                  </div>
                </>
              </button>
            ) : (
              <button onClick={() => setShowModal(true)} css={EditSectionCSS}>
                <>
                  <span>Cancelar</span>
                  <div css={IconBorderCSS}>
                    <Icon name="close" />
                  </div>
                </>
              </button>
            )}
          </>
        }
        cardContainerCSS={CardContainerCSS}
      >
        <Container textAlign="center">
          <div css={ProfileTitleCSS}>
            <figure>
              <img alt="Avatar sample" src={avatarIMG} />
              {type && <figcaption>{ENTITIES[type]}</figcaption>}
            </figure>
            <span css={UserNameCSS}>{`${firstname}  ${lastname}`}</span>
          </div>
          {!editMode ? (
            <>
              <ViewContainer user={userProfile} studentListed={singleStudent} />
              {!showPasswordForm && !has_social && (
                <button
                  css={changePasswordButton}
                  onClick={() => setPasswordForm(true)}
                >
                  Cambiar contraseña
                </button>
              )}

              {showPasswordForm && (
                <ChangePassword
                  user={userProfile}
                  singleStudent={singleStudent}
                  setForm={setPasswordForm}
                />
              )}
            </>
          ) : (
            <FormContainer
              user={userProfile}
              singleStudent={singleStudent}
              submitFunction={
                singleStudent ? handlerSingleStudentUpdate : handleProfileUpdate
              }
            />
          )}
        </Container>
      </Card>

      <ModalContainer
        title={{
          content: '¿Estás seguro?',
        }}
        options={{
          open: showModal,
          size: 'tiny',
          onClose: () => setShowModal(false),
        }}
        actions={modalActions()}
      >
        <p>Si hiciste algún cambio en tu perfil se perderá.</p>
      </ModalContainer>
    </>
  );
};

ProfileCard.defaultProps = {
  userProfile: {},
  title: 'Perfil',
  singleStudent: null,
};

ProfileCard.propTypes = {
  userProfile: PropTypes.object,
  title: PropTypes.string,
  singleStudent: PropTypes.object,
};

export default ProfileCard;
