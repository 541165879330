import React, { useContext, useEffect, useState } from 'react';
import { Button, Dropdown } from 'semantic-ui-react';
import { notificationsActions } from '../../../../../../services/api';
import GlobalContext from '../../../../../../GlobalContext';

import {
  arrangeButtonsCSS,
  buttonsCSS,
  buttonByTypeCSS,
  hiddenNotificationCSS,
  informativeNotificationsCSS,
  moreNotificationsToggleCSS,
  notificationItemCSS,
  viewMoreNotificationsCSS,
} from './style.js';
import { noNewNotificationsCSS } from '../style.js';
import { toast } from 'react-toastify';

const NotificationMenu = () => {
  const {
    state: {
      user,
      notificationData: { notifications, unReadNotifications },
    },
    dispatches,
  } = useContext(GlobalContext);
  const MAXVISIBLENOTIFICATIONS = 3;
  const [viewMore, setViewMore] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [acceptedLoading, setAcceptedLoading] = useState(false);
  const [rejectedLoading, setRejectedLoading] = useState(false);

  useEffect(() => {
    if (!unReadNotifications && (acceptedLoading || rejectedLoading)) {
      setAcceptedLoading(false);
      setRejectedLoading(false);
      setBtnLoading(false);
    }
  }, [notifications]);

  const updateRelationsList = (userType, userData, actionResponse) => {
    const isStudent = userType === 'student';
    const relationStatus = {
      status: actionResponse === 'aceptada' ? 'enabled' : 'disabled',
    };
    const noLicense = { license_status: 'INACTIVE' };
    const userInfo = { ...userData, ...relationStatus };

    isStudent
      ? dispatches.addTutorListStudent(userInfo)
      : dispatches.updateStudentListTutor({ ...userInfo, ...noLicense });
  };

  const getUserAction = (response, notificationID, tutorStudentID) => {
    return notificationsActions(
      user.token,
      user.provider,
      response,
      notificationID,
      tutorStudentID
    );
  };

  const onActionSubmit = async (
    actionResponse,
    responseStatus,
    responseData
  ) => {
    const responseMessage = (actionResponse) => {
      actionResponse === 'aceptada'
        ? toast.success('Solicitud aceptada')
        : toast.warning('Solicitud rechazada');
    };

    if (responseStatus === 200) {
      await dispatches.answeredNotification();
      updateRelationsList(user.type, responseData, actionResponse);
      responseMessage(actionResponse);
    } else {
      toast.error(
        'Ha ocurrido un error en el servicio, por favor intente más tarde.'
      );
    }
  };

  const handleNotification = {
    accepted: async (notificationID, tutorStudentID, notificationIndex) => {
      setBtnLoading(true);
      setAcceptedLoading(true);
      const result = await getUserAction(
        'accepted',
        notificationID,
        tutorStudentID
      );
      onActionSubmit('aceptada', result.status, result.data, notificationIndex);
    },

    rejected: async (notificationID, tutorStudentID, notificationIndex) => {
      setBtnLoading(true);
      setRejectedLoading(true);
      const result = await getUserAction(
        'rejected',
        notificationID,
        tutorStudentID
      );
      onActionSubmit(
        'rechazada',
        result.status,
        result.data,
        notificationIndex
      );
    },
  };

  const getNotificationStyle = (notificationIndex, notificationType) => {
    const isInformative = notificationType === 'informative';
    return notificationIndex < MAXVISIBLENOTIFICATIONS
      ? [notificationItemCSS, isInformative && informativeNotificationsCSS]
      : hiddenNotificationCSS;
  };

  return (
    <div>
      <input
        css={moreNotificationsToggleCSS}
        checked={viewMore}
        readOnly
        type="checkbox"
      />
      {notifications.length ? (
        <>
          {notifications.map((notification, index) => (
            <div
              className={index < MAXVISIBLENOTIFICATIONS ? 'normal' : 'hidden'}
              css={getNotificationStyle(index, notification.notificationtype)}
              key={`notificationid-${notification.key}`}
            >
              <Dropdown.Item {...notification} />
              <div
                className={
                  notification.notificationtype === 'informative' &&
                  notification.notificationtype
                }
                css={arrangeButtonsCSS}
              >
                <Button
                  disabled={btnLoading}
                  loading={acceptedLoading}
                  css={[buttonsCSS, buttonByTypeCSS('Accept')]}
                  className={
                    notification.notificationtype === 'informative' &&
                    notification.notificationtype
                  }
                  onClick={() =>
                    handleNotification.accepted(
                      notification.key,
                      notification.tutorstudentid,
                      index
                    )
                  }
                ></Button>
                <Button
                  disabled={btnLoading}
                  loading={rejectedLoading}
                  css={[buttonsCSS, buttonByTypeCSS('Reject')]}
                  className={
                    notification.notificationtype === 'informative' &&
                    notification.notificationtype
                  }
                  onClick={() =>
                    handleNotification.rejected(
                      notification.key,
                      notification.tutorstudentid,
                      index
                    )
                  }
                ></Button>
              </div>
            </div>
          ))}
          {notifications.length > MAXVISIBLENOTIFICATIONS && (
            <label
              css={viewMoreNotificationsCSS(viewMore)}
              onClick={() => setViewMore(!viewMore)}
            />
          )}
        </>
      ) : (
        <div css={noNewNotificationsCSS}>
          <Dropdown.Item description="No tienes notificaciones nuevas" />
        </div>
      )}
    </div>
  );
};

export default NotificationMenu;
