import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';
import inputContainer from './style';
import OPTIONS from './constant';

const InputContainer = ({
  inputState,
  setInput,
  label,
  optionType,
  customOptions,
  customStyle,
}) => {
  const options = customOptions || OPTIONS[optionType];

  const handleInput = (e, { value }) => {
    setInput(value);
  };

  return (
    <div css={customStyle || inputContainer}>
      <label htmlFor={options.name}>{label}</label>
      <Input focus onChange={handleInput} value={inputState} {...options} />
    </div>
  );
};

InputContainer.propTypes = {
  inputState: PropTypes.string,
  setInput: PropTypes.func,
  label: PropTypes.string,
  optionType: PropTypes.string,
  customOptions: PropTypes.object,
  customStyle: PropTypes.object,
};

InputContainer.defaultProps = {
  inputState: '',
  setInput: () => {},
  label: '',
  optionType: '',
  customOptions: null,
  customStyle: null,
};

export default InputContainer;
