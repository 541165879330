import { css } from '@emotion/core';

const getRequestButtonStyle = {
  Accept: css`
    &.ui.button {
      background-color: #fb6e5b;
      border-style: none;
      color: #ffffff;
      font-weight: 400;
    }

    &:after {
      content: 'Aceptar';
    }
  `,
  Reject: css`
    &.ui.button {
      background-color: #ffffff;
      border: 0.5px solid #0606066e;
      color: #0606068f;
      font-weight: 400;
      margin-top: 10px;
    }

    &:after {
      content: 'Cancelar';
    }
  `,
};

export const arrangeButtonsCSS = css`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  @media only screen and (max-device-width: 767px) {
    flex-direction: row;
    justify-content: center;

    button {
      margin-top: 10px;
    }

    &.informative {
      display: none;
    }
  }
`;

export const buttonsCSS = css`
  &.ui.button {
    align-items: center;
    border-radius: 18px;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    font-family: 'Proxima Nova Semibold';
    height: 32px;
    justify-content: center;
    margin-left: 15px;
    min-width: 90px;
    outline: 0;
    padding: unset;
    visibility: unset;
    width: 7rem;

    &.informative {
      visibility: hidden;
    }

    @media only screen and (max-device-width: 767px) {
      margin-top: 10px;
    }
  }
`;

export const buttonByTypeCSS = (type) => getRequestButtonStyle[type];

export const moreNotificationsToggleCSS = css`
  display: none;

  &:checked ~ div.hidden {
    border-bottom: 0.5px solid #52645d47;
    opacity: 1;
    height: auto;
    padding: 20px;
  }
`;

export const notificationItemCSS = css`
  border-bottom: 0.5px solid #52645d47;
  display: flex;
  font-weight: 400;
  justify-content: space-between;
  opacity: 1;
  padding: 20px;

  div.item {
    column-gap: 20px;
    display: grid;
    grid-template-columns: 75px 221px;
    grid-template-rows: 30px auto;
    grid-template-areas:
      'avatar name'
      'avatar text';

    .description {
      color: #52645d;
      grid-area: name;
      padding-top: 10px;
      white-space: unset;
    }

    .text {
      color: #52645d;
      font-family: 'Proxima Nova Regular';
      grid-area: text;
      line-height: 1.1em;
      white-space: pre-wrap;
    }

    .ui.image {
      border-radius: 100%;
      grid-area: avatar;
      height: 75px;
      width: 75px;
    }
  }

  @media only screen and (max-device-width: 767px) {
    flex-direction: column;
    padding: 5px 35px 15px 35px;

    div.item {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .item > img {
      display: none;
    }

    & ~ label {
      padding: 15px;
    }
  }
`;

export const informativeNotificationsCSS = css`
  @media only screen and (max-device-width: 767px) {
    padding: 25px;
  }
`;

export const hiddenNotificationCSS = css`
  @extend .css-${notificationItemCSS} {
    height: 0;
    opacity: 0;
    overflow: hidden;
    padding: 0;
    transition: opacity 0.3s ease-out;
  }
`;

export const viewMoreNotificationsCSS = (buttonClicked) => css`
  background-color: #ffffff;
  border: none;
  border-bottom: 0.5px solid #52645d47;
  color: #52645d;
  cursor: pointer;
  display: flex;
  font-family: 'Proxima Nova Semibold';
  justify-content: center;
  outline: none;
  padding: 40px;
  width: 100%;

  &:after {
    content: '${buttonClicked ? 'Ver menos' : 'Ver más'}';
  }

  &:hover {
    background-color: #f0f0f0;
  }
`;
