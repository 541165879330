import { post } from 'axios';
const { REACT_APP_BASE_URL } = process.env;

export const changePassword = async (
  provider,
  token,
  { old_password, new_password }
) => {
  try {
    const result = await post(
      `${REACT_APP_BASE_URL}/api/v1/${provider}/change/password`,
      {
        old_password,
        new_password,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result;
  } catch (error) {
    return error.response;
  }
};
