import { get } from 'axios';
const { REACT_APP_BASE_URL } = process.env;

export const fetchUsers = async ({ token, type }) => {
  const provider = `${type}s`;
  try {
    const { data } = await get(`${REACT_APP_BASE_URL}/api/v1/${type}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return { ...data, type, token, provider };
  } catch (e) {
    // TODO: handle error
    return;
  }
};
