import css from '@emotion/css';

export const headerPage = css`
  margin-bottom: 15px;
  background-color: #01a970;
`;

export const containerPage = css`
  display: flex !important;
  justify-content: space-between;
  align-items: center;

  @media only screen and (min-width: 1200px) {
    width: 1127px;
    margin-left: auto;
    margin-right: auto;
  }

  @media only screen and (max-width: 991px) and (min-width: 768px) {
    width: 723px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const containerImgTitle = css`
  flex: 0 1 50%;
`;

export const headerPageImg = css`
  height: 120px;
`;
