import React from 'react';
import PropTypes from 'prop-types';
import { formButtonContainerCSS } from './styles';

export default function RedButton({ title, children, ...rest }) {
  return (
    <div css={formButtonContainerCSS}>
      <button className="ui-button" {...rest}>
        {title || children}
      </button>
    </div>
  );
}

RedButton.defaultProps = {
  title: '',
  children: undefined,
};

RedButton.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
};
