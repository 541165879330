import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, useLocation } from 'react-router-dom';

import GlobalContext from '../GlobalContext';

const PrivateRoute = ({ children, ...rest }) => {
  const { state } = useContext(GlobalContext);
  const location = useLocation();

  return (
    <Route {...rest}>
      {state.user ? (
        children
      ) : (
        <Redirect
          to={{
            // TODO: we can select where to go depending the route
            pathname: '/login',
            state: { from: location },
          }}
        />
      )}
    </Route>
  );
};

PrivateRoute.defaultProps = {
  children: undefined,
  location: {},
};

PrivateRoute.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  location: PropTypes.object,
};

export default PrivateRoute;
