import { get } from 'axios';
const { REACT_APP_BASE_URL } = process.env;

export const getCatalogPrice = async () => {
  try {
    const result = await get(`${REACT_APP_BASE_URL}/api/v2/payments/catalog`);
    return result;
  } catch (error) {
    return error.response;
  }
};
