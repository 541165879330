import React from 'react';
import { PropTypes } from 'prop-types';
import { cardTitleCSS, cardLayoutCSS, defaultContainerCSS } from './style';

const Card = ({ title, children, cardContainerCSS }) => {
  return (
    <div css={cardLayoutCSS}>
      <div css={[defaultContainerCSS, cardContainerCSS]}>
        {title && <div css={cardTitleCSS}>{title}</div>}
        {children}
      </div>
    </div>
  );
};

Card.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  cardContainerCSS: PropTypes.object,
};

Card.defaultProps = {
  title: undefined,
  children: undefined,
  cardContainerCSS: undefined,
};

export default Card;
