import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'semantic-ui-react';
import { useForm } from 'react-hook-form';
import {
  formInputContainerCSS,
  formInputCSS,
  formButtonContainerCSS,
  validationCSS,
  popupContainerCSS,
  containerFormCSS,
} from './style';
import {
  validationFirstName,
  validationLastName,
  validationPhone,
} from '../../../utils/validations';
import InputPopup from '../../InputPopup';
import getSectionName from '../../../utils/getSectionName';

const FormContainer = ({ user, singleStudent, submitFunction }) => {
  const {
    firstname,
    lastname,
    phone,
    contact = '',
    username,
    sections = [],
    code,
    type,
  } = user;
  const { Field } = Form;
  const isStudent = type === 'student';
  const grade = getSectionName(sections);

  const { handleSubmit, register, errors } = useForm({
    mode: 'onChange',
  });

  const onSubmit = (values) => {
    const { firstname, lastname, phone } = values;
    submitFunction({ firstname, lastname, phone });
  };

  return (
    <Form css={containerFormCSS} onSubmit={handleSubmit(onSubmit)}>
      <Field>
        <div css={[formInputContainerCSS, validationCSS]}>
          <label>Nombre</label>
          <input
            css={formInputCSS}
            name="firstname"
            ref={register(validationFirstName)}
            defaultValue={firstname}
          />
          {errors.firstname && (
            <InputPopup
              customStylesCSS={popupContainerCSS}
              content={errors.firstname.message}
              name="firstname"
            />
          )}
        </div>
      </Field>

      <Field>
        <div css={[formInputContainerCSS, validationCSS]}>
          <label>Apellido</label>
          <input
            css={formInputCSS}
            name="lastname"
            ref={register(validationLastName)}
            defaultValue={lastname}
          />
          {errors.lastname && (
            <InputPopup
              customStylesCSS={popupContainerCSS}
              content={errors.lastname.message}
              name="lastname"
            />
          )}
        </div>
      </Field>
      <Field>
        <div css={[formInputContainerCSS, validationCSS]}>
          <label>Celular</label>
          <input
            css={formInputCSS}
            name="phone"
            ref={register(validationPhone)}
            defaultValue={phone}
          />
          {errors.phone && (
            <InputPopup
              customStylesCSS={popupContainerCSS}
              content={errors.phone.message}
              name="phone"
            />
          )}
        </div>
      </Field>

      <div css={formInputContainerCSS}>
        <label>Email</label>
        <Input name="email" value={contact} css={formInputCSS} disabled />
      </div>
      <div css={formInputContainerCSS}>
        <label>Nombre de usuario</label>
        <Input name="username" value={username} css={formInputCSS} disabled />
      </div>
      {isStudent && (
        <div css={formInputContainerCSS}>
          <label>Grado</label>
          <Input name="code" value={grade} css={formInputCSS} disabled />
        </div>
      )}
      {!singleStudent && (
        <div css={formInputContainerCSS}>
          <label>Código</label>
          <Input name="code" value={code} css={formInputCSS} disabled />
        </div>
      )}
      <div css={formButtonContainerCSS}>
        <button className="ui button">Guardar</button>
      </div>
    </Form>
  );
};

FormContainer.defaultProps = {
  user: {},
  singleStudent: null,
  submitFunction: () => {},
};

FormContainer.propTypes = {
  user: PropTypes.object,
  singleStudent: PropTypes.object,
  submitFunction: PropTypes.func,
};

export default FormContainer;
