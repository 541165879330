import React from 'react';
import { Popup, Icon } from 'semantic-ui-react';
import { backgroundRedCSS } from './style';
import { PropTypes } from 'prop-types';

const InputPopup = ({ content, customStylesCSS }) => (
  <Popup
    css={backgroundRedCSS}
    trigger={<Icon name="exclamation" />}
    content={content}
    open={true}
    position="top right"
    style={customStylesCSS}
  />
);

InputPopup.propTypes = {
  content: PropTypes.string,
  customStylesCSS: PropTypes.object,
};

InputPopup.defaultProps = {
  content: undefined,
  customStylesCSS: undefined,
};

export default InputPopup;
