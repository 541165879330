import { css } from '@emotion/core';

const colors = ['#01a970', '#FC6E5B', '#ccc'];
const cursorType = ['not-allowed', 'pointer', 'not-allowed'];

export const nestedTernaryValues = (
  statusColor,
  purchasedLicenseColor,
  genericValue
) => {
  if (purchasedLicenseColor) {
    return genericValue[0];
  }
  return statusColor ? genericValue[1] : genericValue[2];
};

export const buttonBuyLicenseCSS = (statusColor, purchasedLicenseColor) =>
  css`
    display: flex;
    justify-content: center;

    button {
      width: 478px;
      max-width: 95vw;
      min-width: 295px;
      padding: 10px 5px;
      border: 0;
      background-color: ${nestedTernaryValues(
        statusColor,
        purchasedLicenseColor,
        colors
      )};
      color: white;
      font-size: 22px;
      border-radius: 8px;
      margin-bottom: 10px;
      outline: none;
      cursor: ${nestedTernaryValues(
        statusColor,
        purchasedLicenseColor,
        cursorType
      )};
      -webkit-transition: 0.2s;
      transition: 0.2s;
    }
  `;
