import { post } from 'axios';
const { REACT_APP_BASE_URL } = process.env;

export const changeStudentPassword = async (code, token, newpass) => {
  try {
    const result = await post(
      `${REACT_APP_BASE_URL}/api/v2/tutors/auth/password/change/${code}`,

      newpass,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return result;
  } catch (error) {
    return error.response;
  }
};
