import css from '@emotion/css';

const centeredModal = css`
  &.modal.basic {
    text-align: center;
    width: 95%;
  }

  &.modal.basic > div.header {
    display: inline-table;
  }

  &.disable-close {
    .close.icon {
      pointer-events: none;
      cursor: not-allowed;
    }
  }
`;

export default centeredModal;
