import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, Redirect } from 'react-router-dom';
const { REACT_APP_BASE_URL } = process.env;

const ExternalRedirectByToken = ({ baseURL, pathURL }) => {
  const query = new URLSearchParams(useLocation().search);
  const token = query.get('token');

  return token ? (
    (window.location = `${baseURL}${pathURL}?token=${token}`)
  ) : (
    <Redirect to="/login" />
  );
};

ExternalRedirectByToken.defaultProps = {
  baseURL: REACT_APP_BASE_URL,
  pathURL: '',
};

ExternalRedirectByToken.propTypes = {
  baseURL: PropTypes.string,
  pathURL: PropTypes.string,
};

export default ExternalRedirectByToken;
