import { post } from 'axios';
const { REACT_APP_BASE_URL } = process.env;

export const paymentStart = async (licenses, token, promotionalCode = '') => {
  const request = `${REACT_APP_BASE_URL}/api/v2/tutors/auth/payments/start`;

  try {
    const result = await post(
      request,
      {
        licenses,
        promotionalCode,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result;
  } catch (e) {
    return e.response;
  }
};
