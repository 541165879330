import { css } from '@emotion/core';

export const centerGroupButtons = css`
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 24px;
  width: 100%;
`;

export const groupButtons = css`
  border-radius: 8px;
  border: 2px solid rgb(0, 169, 112);
  height: 48px;
  overflow: hidden;

  .ui.button {
    padding: 0;

    &:hover {
      color: rgba(0, 0, 0, 0.8);
      background: none;
    }
    &:focus {
      color: rgba(0, 0, 0, 0.8);
      background: #00a970;
    }
    &:nth-of-type(2) {
      border-left: 2px solid rgb(0, 169, 112);
      border-right: 2px solid rgb(0, 169, 112);
    }

    &.active {
      color: rgba(0, 0, 0, 0.8);
      background: #00a970;
    }
  }
`;

export const customStyle = (widthButtons, backgroundButtons, ColorButtons) => {
  const styles = css`
    width: ${widthButtons};
    .button {
      background: ${backgroundButtons};
      color: ${ColorButtons};
    }
  `;
  return styles;
};
