const profile = { path: 'perfil', label: 'Perfil' };
const home = { path: 'home', label: 'Inicio' };

// TODO: Check this logic after the decision about custom content for users
export const RULES = {
  student: [home, profile],
  tutor: [home, profile, { path: 'estudiantes', label: 'Estudiantes' }],
  teacher: [home, profile],
  studentNational: [home, profile],
};

export const EXAM_GRADE = `6to Secundaria (Pruebas Nacionales)`;
