import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, useLocation } from 'react-router-dom';

import GlobalContext from '../GlobalContext';

const PublicRoute = ({ children, ...rest }) => {
  const { state } = useContext(GlobalContext);
  const location = useLocation();

  return (
    <Route {...rest}>
      {!state.user ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: '/dashboard/home',
            state: { from: location },
          }}
        />
      )}
    </Route>
  );
};

PublicRoute.defaultProps = {
  children: undefined,
};

PublicRoute.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
};

export default PublicRoute;
