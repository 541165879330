import { css } from '@emotion/core';

export const modalTitleCSS = css`
  color: #5f5f5f;
  text-align: center;
`;

export const modalResultContainerCSS = css`
  align-items: center;
  display: flex;
  margin-top: 4%;
`;

export const modalResultTitleCSS = css`
  color: rgb(16, 86, 52);
  flex-basis: 50%;
  text-align: center;
  margin: 0;
`;

export const modalResultItemsCSS = css`
  color: #07a86f;
  font-size: 20px;
  flex-basis: 50%;
  text-align: center;
`;
