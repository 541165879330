import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, useLocation } from 'react-router-dom';
import GlobalContext from '../GlobalContext';
import getSectionName from '../utils/getSectionName';

const isMatcherUser = (user, allowUser) => {
  const allowProps = Object.keys(allowUser);
  let notAllows = allowProps.filter((keyName) => {
    let userPropValue = user[keyName] || '';
    if (keyName === 'sections') {
      userPropValue = getSectionName(userPropValue);
    }
    return userPropValue.match(allowUser[keyName]) === null;
  });

  return notAllows.length === 0;
};

const ProtectedRoute = ({ children, redirectTo, allowUser, ...rest }) => {
  const {
    state: { user },
  } = useContext(GlobalContext);
  const location = useLocation();

  const isLogged = Boolean(user);
  const showProtectedRoute = isLogged && isMatcherUser(user, allowUser);

  return (
    <Route {...rest}>
      {!isLogged ? (
        <Redirect to={{ pathname: '/login', state: { from: location } }} />
      ) : (
        showProtectedRoute && children
      )}
      {isLogged && !showProtectedRoute && (
        <Redirect to={{ pathname: redirectTo, state: { from: location } }} />
      )}
    </Route>
  );
};

ProtectedRoute.defaultProps = {
  path: '',
  redirectTo: '/dashboard/home',
  children: undefined,
  allowUser: {},
};

ProtectedRoute.propTypes = {
  path: PropTypes.string,
  redirectTo: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  allowUser: PropTypes.shape({
    type: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(RegExp)]),
    sections: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(RegExp),
    ]),
  }),
};

export default ProtectedRoute;
