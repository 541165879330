import { post } from 'axios';
import { RELATIONSHIP_REQUEST_ACTIONS } from '../../constants';
const { REACT_APP_BASE_URL } = process.env;

const setTeacherCode = async ({ token, teacherCode }) => {
  try {
    const result = await post(
      `${REACT_APP_BASE_URL}/api/v2/students/auth/teachers/link/tests`,
      { teacherCode },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result;
  } catch ({ response }) {
    return response;
  }
};

const SEND_FROM = {
  students: (token, code) =>
    post(
      `${REACT_APP_BASE_URL}/api/v2/students/auth/add-tutor`,
      {
        tutorCode: code,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ),
  tutors: (token, code) =>
    post(
      `${REACT_APP_BASE_URL}/api/v2/tutors/auth/add-student`,
      {
        studentCode: code,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ),
};

const sendNotification = async (token, provider, receiverCode) => {
  try {
    const data = await SEND_FROM[provider](token, receiverCode);
    return data;
  } catch (e) {
    return e.response;
  }
};

export const linkAccountsByCode = async ({ action, token, code, provider }) => {
  switch (action) {
    case RELATIONSHIP_REQUEST_ACTIONS.student_to_teacher:
      return await setTeacherCode({ token, teacherCode: code });
    case RELATIONSHIP_REQUEST_ACTIONS.sendNotification:
      return sendNotification(token, provider, code);
    default:
      return { status: null, data: null };
  }
};
