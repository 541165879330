import { css, keyframes } from '@emotion/core';
import { shake } from 'react-animations';

export const loginContainerCSS = css`
  display: flex;
  justify-content: center;
`;

export const buttonGroupCSS = css`
  margin-bottom: 0px;

  .ui.buttons {
    width: 100%;
    height: 60px;
    border: none;
    border-radius: 10px 10px 0 0;

    .ui.button {
      background-color: #8cd9bf;
      border-radius: 10px 10px 0 0;
      padding: 11px;
      font-family: 'Amino Regular';
      font-size: 1.2rem;
      color: white;

      &:first-of-type {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }

      &:last-child {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }

      &:nth-of-type(2) {
        border: none;
      }

      &.active,
      &:hover {
        background-color: #00a96f;
        color: white;
      }

      @media (min-width: 420px) {
        font-size: 1.71rem;
      }
    }
  }
`;

export const loginInputCSS = css`
  color: #6d6e6e;
  font-family: 'Proxima Nova Regular';
  font-size: 17px;
  font-weight: 400;
  margin-top: 21px;

  .ui.focus > input {
    background: transparent;
    border: none;
    border-bottom: solid 1px #6178718c;
    border-radius: 0;
    color: #6d6e6e;

    &:focus + i.icon {
      color: #79ceae;
    }
  }

  .ui.input > i.icon {
    color: rgba(121, 206, 173, 0.329);
  }
`;

export const forgotPasswordLinkCSS = (NotDisabled) => css`
  margin-top: 30px;
  a {
    color: ${NotDisabled ? '#4183c4' : '#ccc'};
    pointer-events: ${NotDisabled ? 'pointer' : 'none'};
  }
`;

export const registerLinkCSS = css`
  margin-top: 10px;
`;

export const buttonContainerCSS = (isDisable) => css`
  margin-top: 42px;

  .ui.button {
    background-color: ${isDisable ? '#fc8171' : '#ccc'};
    border-radius: 58px;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.4);
    color: #fff;
    font-family: 'Proxima Nova Semibold';
    font-size: 18px;
    height: 52px;
    padding: 10px 0;
    width: 263px;

    &:hover {
      background-color: ${isDisable ? '#f8624e' : '#ccc'};
    }
  }
`;

export const loginFormCSS = css`
  flex-direction: column;
  display: flex;
  align-items: center;
  text-align: center;
`;

export const loginPageTitleCSS = css`
  font-family: 'Amino Medium';
  color: rgb(16 86 52);
  font-size: 2.25em;
  margin-bottom: 30px;

  &:first-of-type {
    margin-top: 50px;
  }
`;

export const cardLoginCSS = css`
  border-radius: 18px;
  box-shadow: 0 2px 5px 0 rgba(9, 43, 32, 0.15);
  min-height: 385px;
  width: 100vw;
  @media (min-width: 420px) {
    width: 458px;
  }
`;

const shakeAnimation = keyframes`
  ${shake}
`;

export const shakeCSS = (isSake) =>
  isSake
    ? css`
        animation: ${shakeAnimation} 0.9s ease;
      `
    : css`
        animation: none;
      `;

export const modalButtonCSS = css`
  .ui.button {
    width: 250px;
    max-width: 100%;
    height: 70px;
    background-color: #00a96f;
    color: white;

    @media (max-width: 990px) {
      .content & {
        display: block;
        margin: 10px auto;
      }
    }
  }
`;

export const loginGoogleContainerCSS = css`
  margin: 5% 0 5% 0;
`;
