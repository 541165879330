export const validationRole = {
  required: 'Elija su rol.',
};

export const validationFirstName = {
  required: 'El nombre es requerido.',
  pattern: {
    value: /[\saA-zñáéíóú]{2,30}$/i,
    message: 'Nombre inválido.',
  },
};

export const validationLastName = {
  required: 'El apellido es requerido.',
  pattern: {
    value: /[\saA-zñáéíóú]{2,30}$/i,
    message: 'Apellido inválido.',
  },
};

export const validationEducativeCenter = {
  required: 'El nombre del centro educativo es requerido.',
  pattern: {
    value: /[\saA-zñáéíóú]{2,30}$/i,
    message: 'Son necesarios 3 carácteres como mínimo.',
  },
};

export const validationContactEmail = {
  required: 'El correo es requerido.',
  pattern: {
    value: /^[\sA-Z0-9._%+-]+@[A-Z0-9.-]+\.[\sA-Z]{2,4}$/i,
    message: 'Este email no es válido.',
  },
};

export const validationEmail = {
  required: 'El nombre de usuario es requerido.',
  pattern: {
    value: /[\saA-zñáéíóú]{2,30}$/i,
    message: 'Nombre de usuario inválido.',
  },
};

export const validationPassword = {
  required: 'Una contraseña es requerida.',
  pattern: {
    value: /[\w|\W]{6,}$/,
    message: 'La contraseña debe ser de mínimo 6 caracteres.',
  },
};

export const validationPhone = {
  required: 'Introduzca un teléfono',
  pattern: {
    value: /^\d{3}\d{3}\d{4}[ ]?$/i,
    message: 'Teléfono inválido',
  },
};

export const validationSchool = {
  required: 'Introduzca un colegio',
  pattern: {
    value: /^[A-zÀ-ú.-]{3}(\s*[ A-zÀ-ú.-]+)*$/i,
    message: 'Colegio inválido',
  },
};
