import { css } from '@emotion/core';

export const contactValidationCSS = css`
  position: relative;

  i {
    position: absolute;
    top: 5px;
    right: 10px;
    color: #fbbd08;
    font-size: 20px;
  }
`;

export const labelCSS = css`
  font-size: 16px;
  color: rgb(82, 100, 93);
  margin-bottom: 4px;
`;

export const inputCSS = css`
  line-height: 1.21428571em;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  padding: 0.67857143em 1em;
  background: #fff;
  width: 100%;
  margin-bottom: 15px;
  border: solid 1px rgba(0, 169, 112, 0.5);

  &:focus {
    outline: none;
  }
`;

export const modalButtonCSS = css`
  display: flex;
  flex-direction: row-reverse;
`;

export const buttonCSS = css`
  display: flex;
  justify-content: flex-end;

  .negative {
    margin-right: 10px;
  }
`;

export const infoCSS = css`
  padding: 0 15px 15px 15px;
  font-size: 1.1rem;
`;
