export const calculateCharge = (values) => {
  const totalCharge = values.reduce((acc, { value }) => {
    acc += value;
    return acc;
  }, 0);

  return totalCharge;
};

export const formatCurrencyValue = (value) => {
  const formattedValue = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(value);

  return formattedValue;
};
