import { get } from 'axios';
import { getDate } from './utils.js';
const { REACT_APP_BASE_URL } = process.env;

export const getNotifications = async (token, provider, initialLoad) => {
  const requestedObject = {};

  if (!initialLoad) {
    requestedObject.params = {
      date: getDate(),
    };
  }

  try {
    const { data } = await get(
      `${REACT_APP_BASE_URL}/api/v2/${provider}/auth/notifications`,
      {
        ...requestedObject,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (e) {
    return [];
  }
};
