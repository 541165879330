export const getLocalStorageData = (key) => {
  return JSON.parse(localStorage.getItem(key));
};

export const setLocalStoreData = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const addLocalStorageData = (key, code, newItem) => {
  try {
    const data = getLocalStorageData(key) || {};

    if (data[code]) {
      data[code].push(newItem);
    } else {
      data[code] = [newItem];
    }

    setLocalStoreData(key, data);
  } catch {
    return;
  }
};

export const removeLocalStorageData = (key, code, index) => {
  try {
    const data = Object.assign({}, getLocalStorageData(key));
    data[code].splice(index, 1);

    setLocalStoreData(key, data);
  } catch {
    return;
  }
};
