import css from '@emotion/css';

export const informationSectionCSS = css`
  display: grid;
  grid-auto-flow: column;

  p {
    align-self: center;
    color: #52645d;
    font-family: 'Proxima Nova Regular';
    font-size: 21px;
    font-weight: 400;
    width: 100%;
  }

  @media only screen and (max-width: 991px) {
    gap: 2rem;
    grid-auto-flow: row;
  }

  @media only screen and (max-width: 574px) {
    p {
      width: 60vw;
    }
  }

  @media only screen and (min-width: 1200px) {
    gap: 2rem;
  }
`;

export const userHomeContainerCSS = css`
  display: grid;
  gap: 2rem;

  p {
    line-height: 1em;
    margin: 0;
  }
`;

export const welcomeMessageCSS = css`
  border-radius: 18px;
  box-shadow: 0 10px 10px 0 rgba(3, 50, 34, 0.04);
  display: flex;
  justify-content: center;
  padding: 2.5%;
  width: 65rem;

  h1 {
    color: rgb(16, 86, 52);
    font-family: 'Amino Medium';
    font-size: 2.5rem;
  }

  @media only screen and (max-width: 991px) {
    width: 100%;
  }

  @media only screen and (min-width: 1200px) {
    width: 82rem;
  }
`;
