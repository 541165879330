import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import { formatCurrencyValue } from '../utils';
import {
  modalItemCSS,
  modalItemNameCSS,
  modalItemValueCSS,
  modalItemIconCSS,
} from './style';

const ShoppingCartItems = ({ items, onRemove }) => {
  return items.map(({ name, value }, index) => (
    <div css={modalItemCSS} key={`license-${name}-${index}`}>
      <p css={modalItemNameCSS}>Licencia de estudiante para {name}</p>
      <div css={modalItemValueCSS}>{formatCurrencyValue(value)}</div>
      <Button
        onClick={() => onRemove(index)}
        css={modalItemIconCSS}
        icon="close"
      />
    </div>
  ));
};

ShoppingCartItems.propTypes = {
  items: PropTypes.array,
  setItems: PropTypes.func,
};

ShoppingCartItems.defaultProps = {
  items: [],
  setItems: () => {},
};

export default ShoppingCartItems;
