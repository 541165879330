import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import ProtectedRoute from './ProtectedRoute';
import ExternalRedirectByToken from './ExternalRedirectByToken';
import PublicRoute from './PublicRoute';
import Page from '../containers/Page';
import HomePage from '../containers/Home';
import Login from '../containers/Login';
import Profile from '../containers/Profile';
import UserHome from '../containers/UserHome';
import StudentDetail from '../containers/StudentDetail';
import ResetPassword from '../containers/ResetPassword';
import ForgotPassword from '../containers/ForgotPassword';
import RegisterFormStudent from '../containers/RegisterFormStudent';
import NotMatchPage from '../containers/NoMatchPage';
import { PROVIDERS_TYPE_RELATION } from '../constants';

export default function AppRouter() {
  return (
    <Router>
      <Page>
        <Switch>
          <PublicRoute exact path="/">
            {/* <HomePage /> */}
            <Login />
          </PublicRoute>
          <PublicRoute exact path="/login">
            <Login />
          </PublicRoute>
          <PublicRoute exact path="/reset-password/:provider">
            <ResetPassword />
          </PublicRoute>
          <ProtectedRoute
            exact
            path="/dashboard/register-student"
            redirectTo="/dashboard/home"
            allowUser={{
              type: PROVIDERS_TYPE_RELATION.tutors,
            }}
          >
            <RegisterFormStudent />
          </ProtectedRoute>
          <ProtectedRoute
            exact
            path="/dashboard/estudiantes"
            redirectTo="/dashboard/home"
            allowUser={{
              type: PROVIDERS_TYPE_RELATION.tutors,
            }}
          >
            <StudentDetail />
          </ProtectedRoute>
          <PrivateRoute exact path="/dashboard/perfil">
            <Profile />
          </PrivateRoute>
          <PrivateRoute exact path="/dashboard/home">
            <UserHome />
          </PrivateRoute>
          <Route exact path="/forgot-password">
            <ForgotPassword />
          </Route>
          <Route exact path="/update-mail-notification">
            <ExternalRedirectByToken pathURL="/api/v2/update-mail-notification" />
          </Route>
          <Route path="*">
            <NotMatchPage />
          </Route>
        </Switch>
      </Page>
    </Router>
  );
}
