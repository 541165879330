import css from '@emotion/css';
import aminoRegular from './fonts/Amino-Regular.ttf';
import aminoMedium from './fonts/Amino-Medium.ttf';
import aminoBold from './fonts/Amino-AltBold.ttf';
import proximaNovaRegular from './fonts/ProximaNova-Regular.ttf';
import proximaNovaCondRegular from './fonts/ProximaNovaCond-Regular.ttf';
import proximaNovaSemiBold from './fonts/ProximaNova-Semibold.ttf';
import proximaNovaBld from './fonts/ProximaNova-Bold.ttf';
import proximaNovaExtraBld from './fonts/ProximaNova-Extrabld.ttf';
import proximaNovaBlack from './fonts/ProximaNova-Black.ttf';

const fontFaceCSS = css`
   {
    @font-face {
      font-family: 'Amino Regular';
      src: url(${aminoRegular}) format('truetype');
    }

    @font-face {
      font-family: 'Amino Medium';
      src: url(${aminoMedium}) format('truetype');
    }

    @font-face {
      font-family: 'Amino Alt Bold';
      src: url(${aminoBold}) format('truetype');
    }

    @font-face {
      font-family: 'Proxima Nova Regular';
      src: url(${proximaNovaRegular}) format('truetype');
    }

    @font-face {
      font-family: 'Proxima Nova Cond Regular';
      src: url(${proximaNovaCondRegular}) format('truetype');
    }

    @font-face {
      font-family: 'Proxima Nova Semibold';
      src: url(${proximaNovaSemiBold}) format('truetype');
    }

    @font-face {
      font-family: 'Proxima Nova Bold';
      src: url(${proximaNovaBld}) format('truetype');
    }

    @font-face {
      font-family: 'Proxima Nova Extra Bold';
      src: url(${proximaNovaExtraBld}) format('truetype');
    }

    @font-face {
      font-family: 'Proxima Nova Black';
      src: url(${proximaNovaBlack}) format('truetype');
    }
  }
`;

export default fontFaceCSS;
