import { css } from '@emotion/core';

export const CardContainerCSS = css`
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 10px 10px 0 rgba(3, 50, 34, 0.04);
  display: flex;
  flex-direction: column;
  min-height: calc(580px - 44px);
  padding: 22px;
  width: 478px;

  @media (max-width: 481px) {
    width: 350px;
  }

  @media (max-width: 367px) {
    padding: 0 0 22px;
    width: 292px;
  }
`;

export const EditSectionCSS = css`
  background-color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  border: none;

  span {
    align-self: center;
    font-family: Lato;
    font-size: 12px;
    color: #52645d;
    margin-right: 12px;
  }
`;

export const IconBorderCSS = css`
  border: 1px solid #bee9da;
  border-radius: 8px;
  justify-content: center;
  padding: 5px 8px;

  i {
    color: #bee9da;
    height: 15px;
    margin: unset;
    width: 15px;
  }
`;

export const ProfileTitleCSS = css`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: 25px;
  width: 100%;

  img {
    border-radius: 100%;
    height: 100px;
    margin-bottom: 5px;
    width: 100px;
  }

  figcaption {
    color: #52645d;
    font-family: 'Proxima Nova Bold', serif;
    font-size: 12px;
  }
`;

export const UserNameCSS = css`
  color: #52645d;
  font-family: 'Proxima Nova Bold', serif;
  font-size: 22px;
  width: 390px;
`;

export const changePasswordButton = css`
  background-color: #fff;
  border: none;
  border-bottom: solid rgb(0, 169, 112) 2px;
  color: rgb(0, 169, 112);
  cursor: pointer;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-size: 12px;
  line-height: 20px;
  padding: 0 5px;
`;
