import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Button, Form } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { validationPassword } from '../../utils/validations';
import { forgotPassword } from '../../services/api';
import {
  forgotCardCSS,
  forgotCardTitleContainerCSS,
  forgotCardTitleCSS,
  forgotCardFormCSS,
  forgotInputContainerCSS,
  forgotInputLabelCSS,
  forgotInputCSS,
  forgotSubmitButtonCSS,
  popupContainerCSS,
  forgotValidationCSS,
} from './style';

import Card from '../../components/Card';
import InputPopup from '../../components/InputPopup';

const ForgotPassword = () => {
  // TODO: Need to add the logic for the transparent header with the theme task
  const { formState, register, errors, watch, handleSubmit } = useForm({
    mode: 'onChange',
  });
  const { push } = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const token = query.get('token');
  const entity = query.get('entity');

  const submit = async (data) => {
    const { status } = await forgotPassword(entity, token, data.password);

    if (status === 200) {
      toast.success('Se cambió la contraseña exitosamente.', {
        autoClose: 1500,
        onClose: () => push('/login'),
      });
    } else {
      toast.error('Ha ocurrido un error, intente más tarde.');
    }
  };

  return (
    <Card cardContainerCSS={forgotCardCSS}>
      <div css={forgotCardTitleContainerCSS}>
        <h1 css={forgotCardTitleCSS}>Cambiar contraseña</h1>
      </div>
      <Form css={forgotCardFormCSS} onSubmit={handleSubmit(submit)}>
        <Form.Field css={forgotInputContainerCSS}>
          <label css={forgotInputLabelCSS} htmlFor="new-password">
            Nueva contraseña
          </label>
          <div css={forgotValidationCSS}>
            <input
              css={forgotInputCSS}
              name="password"
              type="password"
              autoComplete="off"
              ref={register(validationPassword)}
            />
            {errors.password && (
              <InputPopup
                content={errors.password.message}
                name="password"
                customStylesCSS={popupContainerCSS}
              />
            )}
          </div>
        </Form.Field>
        <Form.Field css={forgotInputContainerCSS}>
          <label css={forgotInputLabelCSS} htmlFor="confirm-password">
            Confirmar nueva contraseña
          </label>
          <div css={forgotValidationCSS}>
            <input
              css={forgotInputCSS}
              name="confirmPassword"
              type="password"
              autoComplete="off"
              ref={register({
                required: 'La contraseña es requerida.',
                validate: (value) =>
                  value === watch('password') ||
                  'Las contraseñas no coinciden.',
              })}
            />
            {errors.confirmPassword && (
              <InputPopup
                content={errors.confirmPassword.message}
                name="confirmPassword"
                customStylesCSS={popupContainerCSS}
              />
            )}
          </div>
        </Form.Field>
        <div css={forgotSubmitButtonCSS}>
          <Button disabled={!formState.isValid}>Aceptar</Button>
        </div>
      </Form>
    </Card>
  );
};

export default ForgotPassword;
