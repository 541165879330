import studentIMG from './img/student.jpg';
import tutorIMG from './img/tutor.jpg';
import teacherIMG from './img/teacher.jpg';

export const DATA_BY_USERTYPE = {
  student: {
    message:
      'Aquí podrás ver y actualizar los datos de tu cuenta. Además, vincular la cuenta de tu tutor.',
    img: studentIMG,
  },
  tutor: {
    message:
      'Aquí podrás ver y actualizar los datos de tu cuenta. Además, vincular la cuenta de tus hijos.',
    img: tutorIMG,
  },
  teacher: {
    message: 'Aquí podrás ver y actualizar los datos de tu cuenta.',
    img: teacherIMG,
  },
};

export const IMPORTANT_MESSAGE = ` Para usar tu cuenta de Okus debes instalar la app ya sea en tu tableta o celular.`;
