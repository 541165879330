import { css } from '@emotion/core';

const inputContainer = css`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;

  label {
    color: rgb(82, 100, 93);
    font-size: 16px;
    font-family: 'ProximaNovaSemibold';
    margin-bottom: 4px;
  }

  .ui.focus > input {
    border: rgba(0, 169, 112, 0.5) 1px solid;
    color: rgba(82 100 93, 0.8);
    font-family: 'proximaNovaRegular';
    font-size: 16px;
    height: 48px;
    outline: none;
    padding: 14px 16px;
    width: 100%;
  }
`;

export default inputContainer;
