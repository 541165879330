import { post } from 'axios';
const { REACT_APP_BASE_URL } = process.env;

export const paymentRegister = async (
  { id, purchase_units },
  token,
  userToken
) => {
  const request = `${REACT_APP_BASE_URL}/api/v2/tutors/auth/payments/register`;
  const code = purchase_units[0].payments.captures[0].id;

  try {
    const result = await post(
      request,
      {
        token,
        code,
        resource: id,
      },
      {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      }
    );

    return result;
  } catch (e) {
    return e.response;
  }
};
