import React, { useState, useEffect, useContext } from 'react';
import GlobalContext from '../../GlobalContext';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Popup } from 'semantic-ui-react';
import { PROVIDERS_TYPE_RELATION } from '../../constants';
import { getToken } from '../../services/api';
import {
  buttonGroupCSS,
  loginInputCSS,
  buttonContainerCSS,
  loginContainerCSS,
  loginPageTitleCSS,
  cardLoginCSS,
  shakeCSS,
  forgotPasswordLinkCSS,
  registerLinkCSS,
  loginGoogleContainerCSS,
  loginFormCSS,
} from './style';

import Card from '../../components/Card';
import ButtonGroup from '../../components/ButtonGroup';
import InputContainer from '../../components/InputContainer';
import GoogleButton from '../../components/GoogleButton';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState('');
  const [isShake, setSaker] = useState(false);
  const [googleProfile, setGoogleProfile] = useState({});
  const { dispatches } = useContext(GlobalContext);
  const { push } = useHistory();

  const loginAndRedirect = async (
    { username, password, social_type, social_token },
    onError
  ) => {
    try {
      const { access_token } = await getToken(
        username,
        password,
        role,
        social_type,
        social_token
      );

      await dispatches.fetchUser({
        token: access_token,
        type: PROVIDERS_TYPE_RELATION[role],
      });

      push('/dashboard/home');
    } catch (e) {
      onError();
    }
  };

  useEffect(() => {
    if (Object.keys(googleProfile).length) {
      setLoading(true);
      setUsername('');
      setPassword('');
      loginAndRedirect(
        {
          ...googleProfile,
          username: googleProfile.email,
        },
        () => {
          toast.error(
            'Algo salio mal: Inténtelo de nuevo o verifique si su cuenta esta registrada'
          );
          setLoading(false);
        }
      );
    }
  }, [googleProfile]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (username === '' || password === '' || role === '') {
      // TODO: Check if we can handle this task with the validations of the form on the homepage
      !role &&
        toast.error(
          'Recuerda seleccionar una de las opciones en la parte superior.'
        );
      (!username || !password) &&
        toast.error('Debe ingresar sus credenciales para acceder.');

      setLoading(false);
    } else {
      loginAndRedirect({ username, password, role }, () => {
        setSaker(true);
        setLoading(false);
      });
    }
  };

  const optionsUser = {
    inputState: username,
    setInput: setUsername,
    customOptions: {
      name: 'name',
      placeholder: 'Usuario o correo',
      type: 'text',
      icon: 'user',
      iconPosition: 'left',
    },

    customStyle: loginInputCSS,
  };

  const optionsPassword = {
    inputState: password,
    setInput: setPassword,
    customOptions: {
      name: 'password',
      placeholder: 'Contraseña',
      type: 'password',
      icon: 'lock',
      iconPosition: 'left',
      autoComplete: 'off',
    },
    customStyle: loginInputCSS,
  };

  return (
    <div css={loginContainerCSS}>
      <form css={loginFormCSS}>
        <h1 css={loginPageTitleCSS}>Iniciar sesión</h1>
        <Card cardContainerCSS={cardLoginCSS}>
          <ButtonGroup setValue={setRole} customClass={buttonGroupCSS} />
          <div css={shakeCSS(isShake)}>
            <InputContainer {...optionsUser} />
            <InputContainer {...optionsPassword} />
          </div>
          <div css={forgotPasswordLinkCSS(!!role)}>
            <Link to={`/reset-password/${role}`}>
              Has olvidado tu contraseña?
            </Link>
          </div>
          {/* <div css={registerLinkCSS}>
            <Link to="/">Registrarse</Link>
          </div> */}
          <div css={buttonContainerCSS(!!role)}>
            <Popup
              trigger={
                <Button type="submit" loading={loading} onClick={handleSubmit}>
                  ENTRAR
                </Button>
              }
              disabled={!!role}
              content="Es necesario que selecciones uno de los tipos de usuarios en la parte superior."
            ></Popup>
          </div>
          {/* <div css={loginGoogleContainerCSS}>
            <GoogleButton
              userRole={role}
              setGoogleProfile={setGoogleProfile}
              buttonMessage="Iniciar sesión con Google"
            />
          </div> */}
        </Card>
      </form>
    </div>
  );
};

export default Login;
