import { css } from '@emotion/core';

export const ProfileInfoCSS = css`
  display: flex;
  justify-content: center;
  padding-bottom: 25px;
  width: 100%;

  tbody {
    display: flex;
    flex-direction: column;
    width: 75%;

    tr {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }
  }
`;

export const ProfileItemsCSS = css`
  td {
    color: #52645d;
    font-family: 'Proxima Nova Regular';
    font-size: 16px;
    font-weight: 400;
    line-height: 35px;
    text-align: start;
    margin-right: 10px;
  }

  tr:nth-of-type(5) td {
    line-height: inherit;
    overflow-wrap: break-word;
    padding: 10px 0;
  }

  th {
    color: #52645d;
    font-family: 'Proxima Nova Semibold';
    font-size: 16px;
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &:hover {
      width: auto;
      white-space: initial;
      overflow: visible;
      cursor: pointer;
    }
  }
`;
