import avatar from '../assets/img/imgDefault.jpg';

export const formatNotifications = (unformattedNotifications) => {
  let [notificationAcumulator, unReadNotifications] = [{}, 0];
  const notificationList = [];

  //TODO: Improve organization of this data structure
  unformattedNotifications.forEach((notificationItem) => {
    notificationItem.data.forEach((details) => {
      const { description, title, tutorStudentId, user } = details;
      notificationAcumulator = {
        key: notificationItem.notificationId,
        description: title,
        text: description,
        image: avatar,
        tutorstudentid: tutorStudentId,
        notificationtype: notificationItem.notificationType,
        user,
        is_read: notificationItem.isRead,
      };
      !notificationItem.isRead && unReadNotifications++;
    });
    notificationList.push(notificationAcumulator);
  });

  return {
    notifications: notificationList,
    unReadNotifications,
  };
};

export const getUpdatedNotifications = (newData, currentNotifications) => {
  const notificationList = newData.notifications;
  const newNotifications = getNewNotifications(
    currentNotifications,
    notificationList
  );
  const notifications = newNotifications.concat(currentNotifications);
  const unReadNotifications = newData.unReadNotifications;

  return { notifications, unReadNotifications };
};

const getNewNotifications = (currentNotifications, notificationList) => {
  const currentNotificationsKeys = currentNotifications.map(
    (notification) => notification.key
  );
  const newNotifications = notificationList.filter(
    (newNotification) => !currentNotificationsKeys.includes(newNotification.key)
  );

  return newNotifications;
};

export const getRespondedRequests = (newNotifications) => {
  const requestResponse = newNotifications.filter(
    (notification) =>
      notification.user !== undefined && notification.is_read === 0
  );
  const respondedRequests = requestResponse.map((response) =>
    newRelationList(response.user, response.text)
  );

  return respondedRequests.length ? respondedRequests : [];
};

const newRelationList = (userData, actionResponse) => {
  const status = actionResponse.includes('rechazado') ? 'disabled' : 'enabled';
  const userInfo = { ...userData, status };

  return userInfo;
};
