import { post } from 'axios';
const { REACT_APP_BASE_URL } = process.env;

export const forgotPassword = async (entity, token, newpass) => {
  try {
    const result = await post(
      `${REACT_APP_BASE_URL}/api/v2/${entity}/password/change`,
      { newpass, token },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result;
  } catch (error) {
    return error.response;
  }
};
