import { css } from '@emotion/core';

export const comfirmMessageCSS = css`
  &.ui.orange {
    background-color: white;
  }

  &.ui.message .header + p {
    margin-top: 10px;
  }
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
`;

export const confirmMessageDetailCSS = css`
  font-size: 14px;
  color: rgb(16, 86, 52);
  font-weight: 700;
`;

export const confirmButtonContainerCSS = css`
  .ui.button {
    background-color: rgb(251, 110, 92);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    font-family: sans-serif;
    font-weight: 400;
    line-height: 1.15;

    &:hover {
      background-color: rgb(255, 143, 129);
    }
  }
`;

export const confirmButtonTextCSS = css`
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: rgba(15, 15, 16, 0.11) 1px solid;
`;

export const confirmButtonMailCSS = css`
  color: rgba(255, 255, 255, 0.49);
  font-size: 12px;
`;

export const confirmMessageBottomCSS = css`
  margin-top: 10px;
  font-size: 14px;
  color: rgb(16, 86, 52);
  button {
    border: none;
    background: none;
    color: rgb(255, 143, 129);
    padding: 0;
  }
`;
