import { css } from '@emotion/core';

export const bigIconCardCSS = css`
  align-items: center;
  max-width: 85vw;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 10px 10px 0 rgba(3, 50, 34, 0.04);
  display: flex;
  flex-direction: column;
  min-height: 150px;
  padding: 22px;
  width: 400px;
`;

export const cardHeaderCSS = css`
  display: flex;
  width: 90%;
  padding-bottom: 10px;
  justify-content: center;
  align-items: center;
  border-bottom: solid 1px #f0f0f0;
`;

export const cardTitleCSS = css`
  color: rgba(84, 90, 87, 0.897);
  font-size: 22px;
  margin: 0;
`;

export const cardItemCSS = css`
  background-color: #ff6d6032;
  cursor: pointer;
  height: 70px;
  width: 70px;
  display: flex;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  margin: 20px;
  cursor: pointer;
`;
