import { post } from 'axios';
const { REACT_APP_BASE_URL } = process.env;

export const verifyUserEmail = async (provider, email) => {
  try {
    const { data } = await post(
      `${REACT_APP_BASE_URL}/api/backoffice/v2/${provider}/isvalid`,
      {
        email,
      },
      {
        headers: { Accept: 'application/json' },
      }
    );
    return data;
  } catch (e) {
    return e;
  }
};
