import appIMG from './img/app.png';

export const INSTALL_APP_PLATFORM = {
  googlePlay:
    'https://pitech.us19.list-manage.com/track/click?u=e6bc1463265520fc52b60fa54&id=99c611df5e&e=d266f4c658',
  appStore:
    'https://pitech.us19.list-manage.com/track/click?u=e6bc1463265520fc52b60fa54&id=b56aeb9f8d&e=d266f4c658',
  windows: 'https://www.microsoft.com/store/productId/9PKWK2B3MCS8',
  amazonStore:
    'https://pitech.us19.list-manage.com/track/click?u=e6bc1463265520fc52b60fa54&id=63535f5619&e=d266f4c658',
};

export const MOBILEAPP_IMG = appIMG;

export const MOBILEAPP_MESSAGE_BY_USERTYPE = {
  student: {
    message:
      'En tu móvil o tableta podrás hacer las tareas que te asigne tu profesor.',
  },
  tutor: {
    message: 'En tu móvil o tableta podrás monitorear las tareas de tus hijos.',
  },
  teacher: {
    message:
      'En tu móvil o tableta podrás asignar y monitorear las tareas de tus estudiantes.',
  },
};
