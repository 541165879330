import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Icon, Button, Grid } from 'semantic-ui-react';
import {
  detailHeaderCSS,
  detailHeaderTitleCSS,
  detailShoppingContainerCSS,
  detailHeaderIconCSS,
  detailHeaderPlusIconCSS,
  centralizedContainerCSS,
} from './style';

import GlobalContext from '../../GlobalContext';
import ConfirmEmailMessage from '../../components/ConfirmMailMessage';
import ProfileCard from '../../components/ProfileCard';
import BigIconCard from '../../components/BigIconCard';
import ShoppingCartModal from './ShoppingCartModal';
import BuyLicenseButton from './BuyLicenseButton';
const { REACT_APP_ENABLE_PAYPAL } = process.env;
const isPayPalEnable = JSON.parse(REACT_APP_ENABLE_PAYPAL);

const StudentDetail = () => {
  const {
    state: { user, shoppingCart, license },
    dispatches,
  } = useContext(GlobalContext);
  const { containsRequiredFields, token, students } = user;
  const [showModal, setShowModal] = useState(false);
  const enableStudentList = students
    .map((student, index) => {
      return {
        ...student,
        globalIndex: index,
      };
    })
    .filter((student) => student.status === 'enabled');

  useEffect(() => {
    if (!shoppingCart.length) {
      dispatches.restoreShoppingList('shopping-cart', user.code);
    }

    isPayPalEnable && dispatches.fetchLicense();
  }, []);

  const isOnShoppingProcess = (StudentIndex) => {
    const match = shoppingCart.filter(({ index }) => index === StudentIndex);
    return !!match.length;
  };

  const renderStudentList = () =>
    enableStudentList.map((student, index) => (
      <Grid.Column
        key={`student-${student.id}` || student.contact_email}
        computer={8}
        tablet={9}
      >
        {isPayPalEnable && (
          <BuyLicenseButton
            isPendingPurchase={student.license_status === 'PENDING'}
            hasLicense={student.license_status === 'ACTIVE' && !!license}
            isInShoppingCart={isOnShoppingProcess(index)}
            onClick={() =>
              dispatches.addShoppingItem('shopping-cart', {
                index,
                value: parseInt(license?.amount.value, 10),
                name: `${student.firstname} ${student.lastname}`,
                globalIndex: student.globalIndex,
              })
            }
          />
        )}
        <ProfileCard
          userProfile={student}
          title={`Estudiante ${index + 1}`}
          singleStudent={{
            index: student.globalIndex,
            token,
          }}
        />
      </Grid.Column>
    ));

  return (
    <div>
      {!containsRequiredFields.validMail && <ConfirmEmailMessage />}
      <div css={detailHeaderCSS}>
        <h1 css={detailHeaderTitleCSS}>Mis Estudiantes</h1>
        {isPayPalEnable && (
          <div css={detailShoppingContainerCSS}>
            <Button
              circular
              icon="shopping cart"
              size="large"
              onClick={() => setShowModal(true)}
            />
          </div>
        )}
      </div>
      <div>
        <Grid centered columns={2}>
          {renderStudentList()}
          {/* <Grid.Column computer={8} tablet={9}>
            <div css={centralizedContainerCSS}>
              <BigIconCard
                title="Agregar estudiante"
                Icon={() => (
                  <Link
                    css={detailHeaderIconCSS}
                    to="/dashboard/register-student"
                  >
                    <Icon
                      css={detailHeaderPlusIconCSS}
                      name="plus"
                      size="small"
                    />
                  </Link>
                )}
              />
            </div>
          </Grid.Column> */}
        </Grid>
      </div>
      {isPayPalEnable && (
        <ShoppingCartModal
          open={showModal}
          setClose={setShowModal}
          students={enableStudentList}
        />
      )}
    </div>
  );
};

export default StudentDetail;
