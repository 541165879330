import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import GlobalContext from '../../../GlobalContext';
import Card from '../../../components/Card';
import { goToProfileCSS, informationImageCSS } from './style';
import { DATA_BY_USERTYPE, IMPORTANT_MESSAGE } from './constant';

const GoToProfileCard = () => {
  const {
    state: {
      user: { type },
    },
  } = useContext(GlobalContext);
  return (
    <Card cardContainerCSS={goToProfileCSS}>
      <Link css={informationImageCSS} to={'/dashboard/perfil'}>
        <img alt="" src={DATA_BY_USERTYPE[type].img} />
        <span>Ve a tu perfil</span>
      </Link>
      <p>{DATA_BY_USERTYPE[type].message}</p>
      <p>
        <b>Importante:</b> {IMPORTANT_MESSAGE}
      </p>
    </Card>
  );
};

export default GoToProfileCard;
