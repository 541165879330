import { get } from 'axios';
const { REACT_APP_BASE_URL } = process.env;

export const sectionsCode = async () => {
  try {
    const { data } = await get(`${REACT_APP_BASE_URL}/api/v2/sections`, {
      headers: { Accept: 'application/json' },
    });
    return data;
  } catch (e) {
    return false;
  }
};
