export const COUNTRIES = [
  {
    value: 'Argentina',
    text: 'Argentina',
  },
  {
    value: 'Bolivia',
    text: 'Bolivia',
  },
  {
    value: 'Chile',
    text: 'Chile',
  },
  {
    value: 'Colombia',
    text: 'Colombia',
  },
  {
    value: 'Costa Rica',
    text: 'Costa Rica',
  },
  {
    value: 'Cuba',
    text: 'Cuba',
  },
  {
    value: 'Ecuador',
    text: 'Ecuador',
  },
  {
    value: 'El Salvador',
    text: 'El Salvador',
  },
  {
    value: 'Guatemala',
    text: 'Guatemala',
  },
  {
    value: 'Honduras',
    text: 'Honduras',
  },
  {
    value: 'México',
    text: 'México',
  },
  {
    value: 'Nicaragua',
    text: 'Nicaragua',
  },
  {
    value: 'Panamá',
    text: 'Panamá',
  },
  {
    value: 'Paraguay',
    text: 'Paraguay',
  },
  {
    value: 'Perú',
    text: 'Perú',
  },
  {
    value: 'República Dominicana',
    text: 'República Dominicana',
  },
  {
    value: 'Uruguay',
    text: 'Uruguay',
  },
  {
    value: 'Venezuela',
    text: 'Venezuela',
  },
  {
    value: 'España',
    text: 'España',
  },
  {
    value: 'Estados Unidos',
    text: 'Estados Unidos',
  },
];
