import { css } from '@emotion/core';

export const formButtonContainerCSS = css`
  & .ui-button,
  & .ui-button:hover {
    background-color: #fb6e5b;
    color: white;
  }

  & .ui-button {
    border-radius: 18px;
    cursor: pointer;
    outline: none;
    padding: 10px 28px;
    min-width: 90px;
    display: inline-block;
    min-height: 1em;
    border: none;
    text-transform: none;
    text-shadow: none;
    font-weight: 700;
    line-height: 1em;
    font-style: normal;
    text-align: center;
    text-decoration: none;
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    height: 32px;
  }
`;
