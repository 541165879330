import css from '@emotion/css';

export const backgroundRedCSS = css`
  &.ui.top {
    border: 2px solid #fbbd08;
    background-color: #feefb3;
    color: #9f6000;
    text-align: center;
  }

  &.ui.top:before {
    background-color: #feefb3;
  }
`;
