import { css } from '@emotion/core';

export const detailHeaderCSS = css`
  display: flex;
  justify-content: space-between;
  padding: 40px 0 85px 10px;
`;

export const detailShoppingContainerCSS = css`
  display: flex;
  align-items: center;

  & .ui.button {
    background: transparent;
    border: #07a86f 2px solid;

    i {
      color: #07a86f;
    }
  }
`;

export const detailHeaderTitleCSS = css`
  color: rgb(16, 86, 52);
  font-size: 45px;
  margin: 0px;

  @media only screen and (max-device-width: 375px) {
    font-size: 2.3em;
  }
`;

export const detailHeaderLinkCSS = css`
  display: flex;
  align-items: center;
`;

export const detailHeaderLinkMessageCSS = css`
  color: rgba(84, 90, 87, 0.897);
  font-size: 15px;
  margin-right: 20px;
  margin-bottom: 0px;
`;

export const detailHeaderIconCSS = css`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ff6d6032;
  border-radius: 8px;

  &:hover {
    background-color: #ff6d604f;
  }
`;

export const detailHeaderPlusIconCSS = css`
  color: rgb(251, 110, 91);

  &.icon {
    margin: auto;
  }
`;

export const centralizedContainerCSS = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
`;
