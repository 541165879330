import { css } from '@emotion/core';
import background from './img/background.png';
import backgroundSecundary from './img/backgroundSecundary.png';

export const PageCSS = css`
  background-position: 50%;
  background-size: 250px;
  border: 0;
  box-sizing: border-box;
  margin: 0;
  min-height: 100vh;
  padding: 0;

  &:before {
    box-sizing: border-box;
  }

  @media (max-width: 812px) and (orientation: landscape) {
    & main {
      min-height: 72vh;
    }
  }
`;

export const PageBackgroundCSS = (isSecundary) =>
  isSecundary
    ? css`
        background-image: url(${backgroundSecundary});
      `
    : css`
        background-image: url(${background});
      `;
