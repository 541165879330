import css from '@emotion/css';

export const titleContainerCSS = css`
  div.modals > div.modal &.ui.header {
    align-items: center;
    border-bottom: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: rgb(16, 86, 52);
    display: flex;
    justify-content: space-between;
    padding: 32px 24px 0 20px;
    width: 100%;
  }
`;

export const customStylesCSS = css`
  &.ui.modal {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-radius: 10px;
    margin: 10px;
    padding: 15px 6px 15px;
    width: 458px;

    @media (max-width: 650px) {
      margin: 10px;
      padding: 15px 6px 15px;
      width: 270px;
    }
  }
`;

export const titleCSS = css`
  font-family: 'Amino Medium', serif;
  font-size: 28px;
  font-weight: 700;
  margin: 0;
`;

export const modalItemsCSS = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 7.5px;
    width: 100%;
  }

  label {
    font-size: 16px;
    color: rgb(82, 100, 93);
    font-family: 'Proxima Nova Semibold';
    margin-bottom: 4px;
    margin-top: 10px;
  }

  &.form.ui input,
  &.form.ui input:focus {
    background-color: white;
    border: rgba(0, 169, 112, 0.5) 1px solid;
    border-radius: 8px;
    height: 48px;
    overflow: hidden;
    padding: 14px 16px;
    width: 100%;
  }

  input[name='school_key'] ~ i.icon.exclamation {
    color: #fbbd08;
    left: 26.88rem;
    position: absolute;
    top: 10rem;
  }
`;

export const phoneErrorCSS = (field) =>
  field !== 'student'
    ? css`
        input[name='phone'] ~ i.icon.exclamation {
          color: #fbbd08;
          left: 26.88rem;
          position: absolute;
          top: 3.6rem;
        }
      `
    : css`
        input[name='phone'] ~ i.icon.exclamation {
          color: #fbbd08;
          left: 26.88rem;
          position: absolute;
          margin-top: 14px;
        }
      `;

export const popupContainerCSS = {
  left: '14px',
  right: '0',
};

export const selectorContainerCSS = css`
  div.ui.selection.dropdown {
    &:hover,
    &:active {
      border: rgba(0, 169, 112, 0.5) 1px solid;
      border-radius: 8px;
    }

    border: rgba(0, 169, 112, 0.5) 1px solid;
    border-radius: 8px;
    height: 48px;
    padding: 14px;
  }

  i.icon.exclamation {
    color: #fbbd08;
    right: 15px;
    position: absolute;
    top: 48px;
  }
`;

export const submitButtonCSS = css`
  button {
    align-self: end;
    width: 25%;
  }

  display: flex;
  padding-top: 30px;

  @media (max-width: 650px) {
    button {
      width: 40%;
    }
  }
`;
