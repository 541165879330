import React, { useContext } from 'react';
import GlobalContext from '../../GlobalContext';

import ConfirmEmailMessage from '../../components/ConfirmMailMessage';
import ProfileCard from '../../components/ProfileCard';
import ViewAssociatedAccounts from './ViewAssociatedAccounts';
import RelateUsersInput from '../../components/RelateUsersInput';

const Profile = () => {
  const { state } = useContext(GlobalContext);
  const { containsRequiredFields, type: userType } = state.user;
  const isTeacher = userType === 'teacher';

  return (
    <div>
      {!containsRequiredFields.validMail && <ConfirmEmailMessage />}
      {!isTeacher && <RelateUsersInput currentUser={state.user} />}
      <ProfileCard userProfile={state.user} />
      <ViewAssociatedAccounts userProfile={state.user} />
    </div>
  );
};

export default Profile;
