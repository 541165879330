const getSectionName = (sections = []) => {
  if (sections.length) {
    const currentSection = sections.filter((section) => !!section.status);
    return currentSection[0].gradeName;
  }

  return;
};

export default getSectionName;
