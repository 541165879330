import { css } from '@emotion/core';

const loadingContainer = css`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  opacity: 0.6;
  top: 0px;
  width: 100%;
  z-index: 1;
`;

export default loadingContainer;
