import React, { useContext } from 'react';
import GlobalContext from '../../../GlobalContext';
import Card from '../../../components/Card';
import { informationImageCSS } from '../GoToProfileCard/style';
import { installMobileBtnsCSS, mobileAppCSS } from './style';
import {
  INSTALL_APP_PLATFORM,
  MOBILEAPP_IMG,
  MOBILEAPP_MESSAGE_BY_USERTYPE,
} from './constant';

const MobileAppCard = () => {
  const {
    state: {
      user: { type },
    },
  } = useContext(GlobalContext);
  return (
    <Card cardContainerCSS={mobileAppCSS}>
      <div css={informationImageCSS}>
        <img alt="" src={MOBILEAPP_IMG} />
      </div>
      <p>{MOBILEAPP_MESSAGE_BY_USERTYPE[type].message}</p>

      <div css={installMobileBtnsCSS}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={INSTALL_APP_PLATFORM.googlePlay}
        >
          <div className="ui button">
            <i className="google play icon"></i>
            <span>
              Disponible en <b>Google Play</b>
            </span>
          </div>
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={INSTALL_APP_PLATFORM.appStore}
        >
          <div className="ui button">
            <i className="apple icon"></i>
            <span>
              Disponible en el <b>App Store</b>
            </span>
          </div>
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={INSTALL_APP_PLATFORM.windows}
        >
          <div className="ui button">
            <i className="windows icon"></i>
            <span>
              Disponible en <b>Windows Store</b>
            </span>
          </div>
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={INSTALL_APP_PLATFORM.amazonStore}
        >
          <div className="ui button">
            <i className="amazon icon"></i>
            <span>
              Disponible en el <b>Amazon Store</b>
            </span>
          </div>
        </a>
      </div>
    </Card>
  );
};

export default MobileAppCard;
