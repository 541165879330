import React, { useState, useContext, useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import GlobalContext from '../../GlobalContext';
import { validationRole } from '../../utils/validations';
import { Container, Form, Grid, Header, Icon } from 'semantic-ui-react';
import {
  CardContainerCss,
  FormContainerCss,
  FormHeaderCss,
  FirstFieldSpaceCss,
  RegisterButtonCss,
  BorderColorValidation,
} from '../../assets/styles/styleForm';
import ButtonGroup from '../ButtonGroup';
import Card from '../Card';
import { addUser, verifyUserEmail } from '../../services/api';
import ModalConfirm from '../ModalConfirm';
import { getToken } from '../../services/api/index';
import { PROVIDERS_TYPE_RELATION } from '../../constants';
import RedButton from '../RedButton';
import InputForm from '../InputForm';
import infInputWithValidations from './constant';
import GoogleButton from '../GoogleButton';

const { Field } = Form;

const Registerform = () => {
  const {
    handleSubmit,
    register,
    errors,
    setError,
    watch,
    getValues,
    reset,
  } = useForm({
    mode: 'onChange',
  });

  const watchPassword = watch('password'); // supply default value as second argument
  const [entitySelected, setEntitySelected] = useState(''); //For role selection
  const [showModal, setShowModal] = useState(false);
  const [toSend, setToSend] = useState({});
  const [googleProfile, setGoogleProfile] = useState({});
  const { dispatches } = useContext(GlobalContext);
  const { push } = useHistory();
  const inputEntityHiddenRef = useRef(null);

  const onSuccess = async () => {
    const { status } = await addUser(entitySelected, toSend);

    if (status !== 409) {
      const { access_token } = await getToken(
        toSend.contact_email,
        toSend.password,
        entitySelected,
        googleProfile.social_type,
        googleProfile.social_token
      );

      setShowModal(false);

      await dispatches.fetchUser({
        token: access_token,
        type: PROVIDERS_TYPE_RELATION[entitySelected],
      });

      push('/dashboard/home');
    } else {
      toast.error('Ya existe un usuario para este email');
      setShowModal(false);
      setGoogleProfile({});
    }
  };

  const createProfile = async (data, onError) => {
    const isValid = await verifyUserEmail(entitySelected, data.email);
    if (isValid) {
      setToSend(data);
      setShowModal(true);
    } else {
      onError();
    }
  };

  useEffect(() => {
    if (errors && errors.role && inputEntityHiddenRef.current) {
      inputEntityHiddenRef.current.parentElement.scrollIntoView();
    }
  }, [errors]);

  useEffect(() => {
    if (Object.keys(googleProfile).length) {
      reset();
      createProfile(
        {
          role: entitySelected,
          firstname: googleProfile.firstname,
          lastname: googleProfile.lastname,
          contact_email: googleProfile.email,
          email: googleProfile.email,
          phone: '',
          school_key: '',
          grade: {},
          social: {
            id: googleProfile.id,
            type: googleProfile.social_type,
          },
        },
        () =>
          toast.error('El email ya existe. Vuelva a intentar con otra cuenta')
      );
    }
  }, [googleProfile]);

  const onSubmit = (values) => {
    // "values" comes with the name attributes of the form inputs
    // Ex.: email: values.email, firstname: values.firstname, ...values.password_confirmation,
    createProfile(
      {
        ...values,
        contact_email: values.email,
        phone: '',
        school_key: '',
        grade: {},
      },
      () => setError('email', 'notMatch', 'Este usuario ya existe.')
    );
  };

  const validationConfirmPassword = {
    validate: (value) =>
      value === watch('password') || 'Las contraseñas no coinciden.',
  };

  const fieldInputForm = (label, name, placeholder, type, validation) => (
    <Field key={label}>
      <InputForm
        label={label}
        name={name}
        placeholder={placeholder}
        type={type}
        validation={validation}
        errorName={errors[name]}
      />
    </Field>
  );

  const onCloseModal = () => {
    if (Object.keys(googleProfile).length) {
      setGoogleProfile({});
    }
    setShowModal(false);
  };

  return (
    <Card
      title={<h1 css={FormHeaderCss}>Formulario de Registro</h1>}
      cardContainerCSS={CardContainerCss}
    >
      <Container css={FormContainerCss}>
        <ModalConfirm
          data={Object.keys(googleProfile).length ? googleProfile : getValues()}
          showModal={showModal}
          onCancel={onCloseModal}
          onSuccess={onSuccess}
        />
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <div>
              <div css={FirstFieldSpaceCss}>
                <Header as="label" textAlign="center">
                  ¿Quién eres?{' '}
                  {errors.role && !entitySelected && (
                    <Icon name="exclamation" color="yellow" />
                  )}
                </Header>
                <ButtonGroup
                  customClass={
                    errors.role && !entitySelected ? BorderColorValidation : {}
                  }
                  setValue={setEntitySelected}
                />
                <input
                  type="hidden"
                  name="role"
                  value={entitySelected}
                  ref={(ref) => {
                    inputEntityHiddenRef.current = ref;
                    register(ref, validationRole);
                  }}
                />
                <Grid centered>
                  <GoogleButton
                    userRole={entitySelected}
                    setGoogleProfile={setGoogleProfile}
                    buttonMessage="Registrarte con google"
                  />
                </Grid>
              </div>
              {infInputWithValidations.map((infInputArr, index) => (
                <Form.Group key={index} widths="equal">
                  {infInputArr.map(
                    ({ label, name, placeholder, type, validation }) => {
                      if (name === 'password_confirmation') {
                        if (!errors.password && watchPassword) {
                          return fieldInputForm(
                            label,
                            name,
                            placeholder,
                            type,
                            register(validationConfirmPassword)
                          );
                        }
                      } else {
                        return fieldInputForm(
                          label,
                          name,
                          placeholder,
                          type,
                          register(validation)
                        );
                      }
                    }
                  )}
                </Form.Group>
              ))}
            </div>
            <Grid centered>
              <RedButton css={RegisterButtonCss} type="submit">
                Registrar
              </RedButton>
            </Grid>
          </div>
        </Form>
      </Container>
    </Card>
  );
};

export default Registerform;
