import { css } from '@emotion/core';

export const cardContainerCss = css`
  align-items: center;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 10px 10px 0 rgba(3, 50, 34, 0.04);
  display: flex;
  flex-direction: column;
  min-height: calc(780px - 44px);
  padding: 22px;
  width: 750px;
`;

export const titleandCloseCss = css`
  display: contents;
  a {
    width: 57px;
    height: 32px;
    color: rgb(190, 233, 218);
    font-size: 20px;
  }
`;

export const imgCss = css`
  align-items: center;
  display: flex;
  margin: 20px 0 10px;
  width: 110px;
  border-radius: 100%;
`;

export const popupContainerCSS = {
  left: '8px',
  right: '0',
  top: '0px',
};
