import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { PageCSS, PageBackgroundCSS } from './style';
import { Container } from 'semantic-ui-react';
import { ToastContainer } from 'react-toastify';
import { Global } from '@emotion/core';
import globalStyles from './utils/globalStyles';
import { URL_LIST } from './constant';

import { Provider } from '../../GlobalContext';
import Header from './Header';
import Loading from '../../components/Loading';
import Footer from './Footer';

const getPageConfig = (url) => {
  const option = url.match(`^/([^/]*).*$`)[1];
  return (
    URL_LIST[option] || {
      showHeader: true,
      showFooter: false,
      isSecundary: false,
    }
  );
};

const Page = ({ children }) => {
  const location = useLocation();
  // TODO: Change this logic to use themes
  const { showHeader, showFooter, isSecundary } = getPageConfig(
    location.pathname
  );

  return (
    <>
      <Global styles={globalStyles} />
      <div css={[PageCSS, PageBackgroundCSS(isSecundary)]}>
        <Provider>
          <Loading>
            {showHeader && <Header />}
            <main role="main">
              <Container>
                {children}
                <ToastContainer />
              </Container>
            </main>
            {showFooter && <Footer />}
          </Loading>
        </Provider>
      </div>
    </>
  );
};

Page.defaultProps = {
  children: undefined,
};

Page.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
};

export default Page;
