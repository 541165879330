import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { changePassword, changeStudentPassword } from '../../../services/api';
import { PROVIDER } from './constant';
import {
  buttonContainerCSS,
  fromInputContainerCSS,
  formLabelCSS,
  formInputCSS,
  formSubmitButtonCSS,
  formErrorMessage,
} from './style';

const ChangePassword = ({ user, singleStudent, setForm }) => {
  const { register, errors, handleSubmit, watch } = useForm();
  const token = user.token || singleStudent.token;

  const onSubmitProfile = async (data) => {
    const { password, newPassword } = data;

    const result = await changePassword(PROVIDER[user.type], token, {
      old_password: password,
      new_password: newPassword,
    });

    if (result.status === 200) {
      toast.success('Se cambió la contraseña exitosamente.');
      setForm(false);
    } else {
      const message =
        result.status === 401
          ? 'Contraseña incorrecta.'
          : 'Ha ocurrido un error, intente más tarde.';
      toast.error(message);
    }
  };

  const onSubmitSingleStudent = async (data) => {
    const { newPassword } = data;

    const result = await changeStudentPassword(user.code, token, {
      newpass: newPassword,
    });

    if (result.status === 200) {
      toast.success('Se cambió la contraseña exitosamente.');
      setForm(false);
    } else {
      const message =
        result.status === 401
          ? 'Contraseña incorrecta.'
          : 'Ha ocurrido un error, intente más tarde.';
      toast.error(message);
    }
  };

  const onSubmit = singleStudent ? onSubmitSingleStudent : onSubmitProfile;
  // TODO: Use the global validation; the inputPopop for the error and the fix the responsive style
  return (
    <div>
      <div css={buttonContainerCSS}>
        <button onClick={() => setForm(false)}>
          <Icon name="close" size="large" />
        </button>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {!singleStudent && (
          <div css={fromInputContainerCSS}>
            <label css={formLabelCSS} htmlFor="password">
              Escriba su contraseña actual
            </label>
            <input
              css={formInputCSS}
              name="password"
              type="password"
              autoComplete="off"
              ref={register({
                required: 'Una contraseña es requerida.',
              })}
            />
            {errors.password && (
              <span css={formErrorMessage}>{errors.password.message}</span>
            )}
          </div>
        )}
        <div css={fromInputContainerCSS}>
          <label css={formLabelCSS} htmlFor="newPassword">
            Nueva contraseña
          </label>
          <input
            css={formInputCSS}
            name="newPassword"
            type="password"
            autoComplete="off"
            ref={register({
              required: 'Una contraseña es requerida.',
              pattern: {
                value: /[\w|\W]{6,}$/,
                message: 'La contraseña debe ser de mínimo 6 caracteres.',
              },
            })}
          />
          {errors.newPassword && (
            <span css={formErrorMessage}>{errors.newPassword.message}</span>
          )}
        </div>
        {!singleStudent && (
          <div css={fromInputContainerCSS}>
            <label css={formLabelCSS} htmlFor="confirmPassword">
              Confirmar nueva contraseña
            </label>
            <input
              css={formInputCSS}
              name="confirmPassword"
              type="password"
              autoComplete="off"
              ref={register({
                validate: (value) =>
                  value === watch('newPassword') ||
                  'Las contraseñas no coinciden.',
              })}
            />
            {errors.confirmPassword && (
              <span css={formErrorMessage}>
                {errors.confirmPassword.message}
              </span>
            )}
          </div>
        )}
        <div css={formSubmitButtonCSS}>
          <button className="ui button">Cambiar contraseña</button>
        </div>
      </form>
    </div>
  );
};

ChangePassword.defaultProps = {
  user: {},
  singleStudent: undefined,
  setForm: false,
};

ChangePassword.propTypes = {
  user: PropTypes.object,
  singleStudent: PropTypes.object,
  setForm: PropTypes.func,
};
export default ChangePassword;
