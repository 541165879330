import React from 'react';
import { PropTypes } from 'prop-types';
import { Header, Modal } from 'semantic-ui-react';
import centeredModal from './style';

const ModalContainer = ({
  actions,
  children,
  customStylesCSS,
  options,
  title,
}) => {
  return (
    <Modal css={[centeredModal, customStylesCSS]} {...options}>
      <Header {...title} />
      <Modal.Content>{children}</Modal.Content>
      {actions && <Modal.Actions>{actions}</Modal.Actions>}
    </Modal>
  );
};

ModalContainer.defaultProps = {
  actions: null,
  children: {},
  customStylesCSS: undefined,
  options: undefined,
  title: undefined,
};

ModalContainer.propTypes = {
  actions: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.object,
  ]),
  customStylesCSS: PropTypes.object,
  options: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default ModalContainer;
