import css from '@emotion/css';

export const cardContainerCSS = css`
  background-color: #ffffff;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  border-radius: 10px;
  box-shadow: 0 10px 10px 0 rgba(3, 50, 34, 0.04);
  margin-top: 20px;
  padding: 30px;
  width: 80%;
`;

export const containerCSS = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  img {
    width: 25%;
  }
`;

export const contentTextCSS = css`
  display: flex;
  justify-content: center;
  text-align: center;

  h1 {
    font-weight: 700;
    padding: 15px;
  }

  h2,
  a {
    font-weight: 100;
    padding: 15px;
  }

  @media only screen and (min-width: 320px) {
    h1 {
      font-size: 1.2rem;
      padding: 10px 0;
    }

    h2,
    a {
      font-size: 1.13rem;
      padding: 10px 0;
    }
  }

  @media only screen and (min-width: 768px) {
    h1 {
      font-size: 2rem;
      padding: 15px;
    }
    h2,
    a {
      font-size: 1.6rem;
      padding: 15px;
    }
  }
`;
