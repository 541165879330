import { post } from 'axios';
const {
  REACT_APP_BASE_URL,
  REACT_APP_MAC_ADDRESS,
  REACT_APP_CLIENT_SECRET,
} = process.env;

export const getToken = async (
  username,
  password,
  provider,
  social_type,
  social_token
) => {
  const { data } = await post(`${REACT_APP_BASE_URL}/oauth/token`, {
    grant_type: 'password',
    client_id: 2,
    client_secret: `${REACT_APP_CLIENT_SECRET}`,
    scope: '*',
    username: username,
    password: password,
    mac_address: `${REACT_APP_MAC_ADDRESS}`,
    provider: provider,
    social_type,
    social_token,
  });
  return data;
};
