import { css } from '@emotion/core';

export const footerCSS = css`
  background-color: #004d35;
  bottom: 0;
  color: #fff;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-size: 16px;
  justify-content: space-around;
  min-height: 10vh;
  position: relative;
  padding: 1em 0em;
  width: 100%;

  @media (min-width: 321px) {
    position: fixed;
  }

  @media (max-width: 812px) and (orientation: landscape) {
    position: relative;
  }
`;

export const footerLinksCSS = css`
  &.ui.icon {
    background-color: #00a36c;
    color: white;
    margin-right: 10px;
  }
`;
