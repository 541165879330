import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import GlobalContext from '../../../GlobalContext';
import { updateProfile, verifyUserEmail } from '../../../services/api';
import {
  modalButtonCSS,
  labelCSS,
  inputCSS,
  contactValidationCSS,
  buttonCSS,
  infoCSS,
} from './style';
import { useForm } from 'react-hook-form';
import ModalContainer from '../../ModalContainer';
import { popupContainerCSS } from '../../../assets/styles/styleForm';
import InputPopup from '../../InputPopup';
import { validationContactEmail } from '../../../utils/validations';

const ChangeMailModal = ({ open, setOpen }) => {
  const { handleSubmit, register, errors, setError, getValues } = useForm({
    mode: 'onChange',
  });

  const { state, dispatches } = useContext(GlobalContext);
  const { type, token, provider, username, contact } = state.user;
  const [isShowInf, setIsshowInf] = useState(false);
  const inputContact = getValues('contact');

  const modalOptions = {
    title: {
      content: 'Cambio de correo electrónico',
    },
    options: {
      open: open,
      size: 'tiny',
      closeIcon: true,
      onClose: () => setOpen(false),
    },
  };

  const handleCancel = () => {
    setOpen(false);
    setIsshowInf(false);
  };

  const handlerUpdateMail = async (contact) => {
    const result = await updateProfile(
      provider,
      { contact_email: contact },
      token
    );

    if (result.status === 200) {
      toast.success('Operación exitosa.');
      dispatches.updateUser({ ...result.data, type, token, provider });
    } else {
      toast.error('Ha ocurrido un error con el servicio, intenta más tarde.');
    }
    setOpen(false);
  };

  const handleSave = () => {
    handlerUpdateMail(inputContact);
    setOpen(false);
    setIsshowInf(false);
  };

  const onSubmit = (values) => {
    verifyUserEmail(provider, values.contact).then((isAvailable) => {
      if (!isAvailable) {
        setError('contact', 'notMatch', 'Este usuario ya existe.');
      } else if (contact === values.contact) {
        toast.warn('Ingrese un correo electronico diferente al que ya tiene.');
      } else if (username === contact) {
        handlerUpdateMail(values.contact);
        setIsshowInf(false);
      } else {
        setIsshowInf(true);
      }
    });
  };

  return (
    <ModalContainer {...modalOptions}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {isShowInf ? (
          <div css={infoCSS}>
            El cambio que realizará solo afectará al <strong>email</strong>{' '}
            donde recibirá notificaciones. Su usuario para ingresar a la
            aplicación de Okus seguirá siendo <strong>{username}</strong>
          </div>
        ) : (
          <>
            <label css={labelCSS}>Nuevo correo eléctronico</label>
            <div css={contactValidationCSS}>
              <input
                name="contact"
                type="text"
                placeholder="Escribe tu correo"
                ref={register(validationContactEmail)}
                css={inputCSS}
              />
              {errors.contact && (
                <InputPopup
                  content={errors.contact.message}
                  customStylesCSS={popupContainerCSS}
                  name="password"
                />
              )}
            </div>
          </>
        )}

        {isShowInf ? (
          <div css={buttonCSS}>
            <button className="ui button negative" onClick={handleCancel}>
              Cancelar
            </button>
            <button className="ui button positive" onClick={handleSave}>
              Guardar
            </button>
          </div>
        ) : (
          <div css={modalButtonCSS}>
            <button className="ui button positive">
              {username === contact ? 'Guardar' : 'Continuar'}
            </button>
          </div>
        )}
      </form>
    </ModalContainer>
  );
};

ChangeMailModal.defaultProps = {
  open: false,
  setOpen: () => {},
};

ChangeMailModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default ChangeMailModal;
