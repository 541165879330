import { css } from '@emotion/core';

export const buttonContainerCSS = css`
  display: flex;
  justify-content: flex-end;
  height: 25px;
  margin-right: 12%;
  width: 100%;

  & button {
    background-color: #ffffff;
    border: none;
    cursor: pointer;
  }

  & .close.icon {
    color: #ff6e60;
  }
`;

export const fromInputContainerCSS = css`
  display: flex;
  flex-direction: column;
  padding: 0px 20px 20px 20px;
`;

export const formLabelCSS = css`
  align-self: flex-start;
  color: rgb(82, 100, 93);
  font-size: 16px;
  padding-bottom: 5px;
`;

export const formInputCSS = css`
  border: rgba(0, 169, 112, 0.5) solid 1px;
  border-radius: 8px;
  color: rgba(82, 100, 93, 0.8);
  font-size: 16px;
  font-family: 'Proxima Nova Regular';
  height: 34px;
  outline: none;
  padding: 0 10px;
`;

export const formSubmitButtonCSS = css`
  padding-top: 32px;

  & .ui.button {
    background-color: rgb(251, 110, 91);
    border-radius: 18px;
    color: white;
    cursor: pointer;
    font-size: 16px;
    font-family: 'Proxima Nova Regular', serif;
    outline: none;
    padding: 11px 28px;
  }
`;

export const formErrorMessage = css`
  color: red;
`;
