import { css } from '@emotion/core';

export const titleEmailCSS = css`
  text-align: center;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  line-height: 1.28571429em;
  margin: calc(2rem - 0.14285714em) 0 1rem;
  font-weight: 700;
  padding: 0;
  font-size: 18px;
`;
