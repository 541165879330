import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { Icon } from 'semantic-ui-react';
import { GoogleLogin } from 'react-google-login';
import { toast } from 'react-toastify';
import { ERRORS } from './constant';

const re = /\b(\w*cookies\w*)\b/i;

const GoogleButton = ({ userRole, setGoogleProfile, buttonMessage }) => {
  const [haveError, setHaveError] = useState(false);

  const responseGoogle = (response) => {
    const { tokenId, googleId, profileObj } = response;
    setGoogleProfile({
      id: googleId,
      firstname: profileObj.givenName,
      lastname: profileObj.familyName,
      email: profileObj.email,
      social_type: 'google',
      social_token: tokenId,
    });
  };

  const responseErrorGoogle = (response) => {
    const errorType = re.test(response.details) ? 'cookie' : 'common';

    if (errorType === 'cookie') {
      setHaveError(true);
    }

    toast.error(ERRORS[errorType], { autoClose: 8000 });
  };

  return (
    <GoogleLogin
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
      onSuccess={responseGoogle}
      onFailure={responseErrorGoogle}
      cookiePolicy={'single_host_origin'}
      icon={false}
      theme="dark"
      disabled={!userRole || haveError}
    >
      <Icon name="google" />
      <span>{buttonMessage}</span>
    </GoogleLogin>
  );
};

GoogleButton.propTypes = {
  userRole: PropTypes.string,
  setGoogleProfile: PropTypes.func,
  buttonMessage: PropTypes.string,
};

GoogleButton.defaultProps = {
  userRole: '',
  setGoogleProfile: undefined,
  buttonMessage: '',
};

export default GoogleButton;
