import css from '@emotion/css';
import { cardLayoutCSS, cardTitleCSS } from '../../../components/Card/style';

const h1Style = `
  font-family: 'Amino Medium';
  font-size: 32px;
  margin-bottom: 0;
`;

export const spaceCSS = css`
  padding-top: 25px;
`;

export const cardStyleCSS = css`
  border: 1px solid rgba(97, 120, 113, 0.24);
  border-radius: 18px;
  box-shadow: 0 10px 10px 0 rgba(3, 50, 34, 0.04);
  display: flex;
  flex-direction: column;
  max-width: 700px;
  min-height: 200px;
  overflow: hidden;
  padding: 30px 40px;

  .css-${cardTitleCSS.name} {
    border-bottom: 1px solid #dedede;
    height: 60px;
    padding: 0;
    margin-left: 3%;

    & h1 {
      ${h1Style}
    }
  }

  .css-${cardTitleCSS.name} + div {
    font-size: 15px;
    padding: 15px;
  }

  @media only screen and (max-device-width: 695px) {
    padding: 25px;

    .css-${cardTitleCSS.name} {
      height: 100%;
    }
  }
`;

export const professorCodeCSS = css`
  display: flex;
  justify-content: center;
  padding-bottom: 2.5%;

  .css-${cardLayoutCSS.name} {
    justify-content: flex-end;
  }
`;
