import { post } from 'axios';
const { REACT_APP_BASE_URL } = process.env;

export const sendValidationMail = async (token, provider) => {
  try {
    const { data } = await post(
      `${REACT_APP_BASE_URL}/api/v2/${provider}/auth/resend/mail-verification`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (e) {
    return false;
  }
};
