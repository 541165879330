import { put } from 'axios';
const { REACT_APP_BASE_URL } = process.env;

export const updateAssociateProfile = async (token, id, data) => {
  try {
    const result = await put(
      `${REACT_APP_BASE_URL}/api/v2/tutors/auth/students/${id}`,
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result;
  } catch (e) {
    return e.response;
  }
};
