import { css } from '@emotion/core';

export const forgotCardCSS = css`
  width: 478px;
  min-height: 466px;
  padding: 22px;
  border-radius: 10px;
  box-shadow: 0 10px 10px 0 rgba(3, 50, 34, 0.04);
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const forgotCardTitleContainerCSS = css`
  align-items: center;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  height: 68px;
  padding: 0 1%;
  width: 90%;
  margin-bottom: 39px;
`;

export const forgotCardTitleCSS = css`
  color: #105634;
  font-family: 'Amino Medium';
  margin: 0;
`;

export const forgotCardFormCSS = css`
  display: flex;
  flex-flow: column;
  width: 90%;

  &.ui.form {
    font-family: 'Proxima Nova Regular';
    font-size: 1.2rem;

    & .field {
      margin-bottom: 25px;
    }
  }
`;

export const forgotInputContainerCSS = css`
  display: flex;
  flex-direction: column;
`;

export const forgotInputLabelCSS = css`
  font-size: 0.9em;
  margin-bottom: 4px;
`;

export const forgotInputCSS = css`
  border-radius: 8px;
  color: rgba(82, 100, 93, 0.8);
  height: 3.2rem;
  outline: none;
  padding-left: 10px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(82, 100, 93, 0.9);

  &:focus {
    border-color: #85b7d9;
  }
`;

export const forgotSubmitButtonCSS = css`
  display: flex;
  justify-content: center;
  margin-top: 31px;

  .ui.button {
    background-color: #fc8171;
    border-radius: 30px;
    color: #fff;
    font-size: 16px;
    font-family: 'Proxima Nova Bold';
    height: 50px;
    width: 70%;
  }
`;
export const forgotValidationCSS = css`
  position: relative;

  i.exclamation.icon {
    font-size: 1em;
    color: #fbbd08;
    left: 25.88rem;
    position: absolute;
    top: 0.8rem;
    @media (max-width: 375px) {
      left: 16rem;
    }
  }
`;

export const popupContainerCSS = {
  right: '0',
  top: '-1px',
  left: '0.9%',
};
