import React, { useEffect, useState, useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import GlobalContext from '../../GlobalContext';
import { Container, Form, Grid } from 'semantic-ui-react';
import {
  FormContainerCss,
  FormHeaderCss,
  RegisterButtonCss,
} from '../../assets/styles/styleForm';
import { cardContainerCss, titleandCloseCss, imgCss } from './style';
import Card from '../../components/Card';
import { addUser, verifyUserEmail, sectionsCode } from '../../services/api';
import userEmpty from '../../assets/img/imgDefault.jpg';
import { toast } from 'react-toastify';
import RedButton from '../../components/RedButton';
import InputForm from '../../components/InputForm';
import infInputWithValidations from './constant';

const { Field, Group, Select } = Form;

const Registerform = () => {
  const { handleSubmit, register, errors, setError, watch, control } = useForm({
    mode: 'onChange',
  });

  const { push } = useHistory();
  const {
    state: { user },
    dispatches,
  } = useContext(GlobalContext);
  const [grades, setGrades] = useState([]);

  useEffect(() => {
    let getGrades = async () => {
      const codes = await sectionsCode();
      setGrades(
        codes.map(({ name: text, code: value }) => ({ text, value })).reverse()
      );
    };
    getGrades();
  }, []);

  const onSubmit = (values) => {
    let {
      firstname,
      lastname,
      email,
      password,
      section_codes,
      school,
    } = values;

    section_codes = section_codes.split();
    const toSend = {
      contact_email: user.contact,
      firstname,
      lastname,
      email,
      password,
      section_codes,
      school,
      tutor_code: user.code,
    };

    verifyUserEmail('students', values.email).then(async (isAvailable) => {
      if (!isAvailable) {
        setError('email', 'notMatch', 'Este usuario ya existe.');
      } else {
        const { data } = await addUser('students', toSend);
        const tutorStudentRelation = data.tutors.filter(
          ({ code }) => code === user.code
        );

        dispatches.updateStudentListTutor({
          ...data,
          status: tutorStudentRelation[0].status,
        });
        push('/dashboard/estudiantes');
        toast.success('La cuenta se creo correctamente');
      }
    });
  };

  const validationConfirmPassword = {
    validate: (value) =>
      value === watch('password') || 'Las contraseñas no coinciden.',
  };

  return (
    <Card
      title={
        <div css={titleandCloseCss}>
          <h1 css={FormHeaderCss}>Estudiante</h1>
          <Link to="/dashboard/alumnos">x</Link>
        </div>
      }
      cardContainerCSS={cardContainerCss}
    >
      <img src={userEmpty} css={imgCss} alt="User" />
      <Container css={FormContainerCss}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <div>
              {infInputWithValidations.map((infInputArr, index) => (
                <Group key={index} widths={index === 3 ? 'two' : 'equal'}>
                  {infInputArr.map(
                    ({
                      label,
                      name,
                      placeholder,
                      type,
                      validation,
                      hasChildren,
                      popUpMessage,
                    }) => (
                      <Field key={label}>
                        <InputForm
                          label={label}
                          name={name}
                          popUpMessage={popUpMessage}
                          placeholder={placeholder}
                          type={type}
                          validation={
                            validation &&
                            register(
                              name === 'password_confirmation'
                                ? validationConfirmPassword
                                : validation
                            )
                          }
                          errorName={errors[name]}
                        >
                          {hasChildren && (
                            <Controller
                              as={Select}
                              options={grades}
                              control={control}
                              onChange={(event) => event[1].value}
                              name="section_codes"
                              rules={{ required: true }}
                              placeholder="Selecciona un grado"
                            />
                          )}
                        </InputForm>
                      </Field>
                    )
                  )}
                </Group>
              ))}
            </div>
            <Grid centered>
              <RedButton css={RegisterButtonCss} type="submit">
                Registrar
              </RedButton>
            </Grid>
          </div>
        </Form>
      </Container>
    </Card>
  );
};

export default Registerform;
