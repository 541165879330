export const URL_LIST = {
  login: {
    showHeader: false,
    showFooter: false,
    isSecundary: false,
  },
  'reset-password': {
    showHeader: false,
    showFooter: false,
    isSecundary: true,
  },
  'forgot-password': {
    showHeader: true,
    showFooter: true,
    isSecundary: true,
  },
};
