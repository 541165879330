import React, { useContext } from 'react';
import { Dropdown, Icon } from 'semantic-ui-react';
import { NavLink, useHistory } from 'react-router-dom';
import imgDefault from '../../../../assets/img/imgDefault.jpg';
import GlobalContext from '../../../../GlobalContext';
import NotificationCenter from './NotificationCenter/index';
import getSectionName from '../../../../utils/getSectionName';

import { RULES, EXAM_GRADE } from './constants';
import {
  navListCSS,
  navListItemCSS,
  navLinkItemCSS,
  navLinkCSS,
  navMenuItemCSS,
  navImageCSS,
  navDeviceCSS,
  navLayout,
} from './style';

// TODO: delete this function when we got a way to identify the students that are on the national exam
const getRule = (type, sections) => {
  const currentSection = getSectionName(sections);
  if (currentSection && currentSection === EXAM_GRADE) {
    return 'studentNational';
  }

  return type;
};

const NavigationItems = () => {
  const { state, dispatches } = useContext(GlobalContext);
  const { firstname, lastname, type, sections = [] } = state.user || {};
  const { push } = useHistory();
  const TEACHER = 'teacher';
  const isNotTeacher = type !== TEACHER;
  const rule = getRule(type, sections);
  const mobileBarsIcon = (
    <Icon style={{ color: '#FFFF' }} size="big" name="bars" />
  );

  const handleLogout = async () => {
    await dispatches.logoutUser();

    if (state.shoppingCart.length) {
      dispatches.changeShoppingListState([]);
    }

    push('/login');
  };

  return (
    <nav css={navLayout}>
      {state.user ? (
        <div css={navDeviceCSS}>
          <ul css={navListCSS}>
            <div css={navListItemCSS}>
              {RULES[rule].map(({ path, label }) => (
                <li css={navLinkItemCSS} key={`item-${path}`}>
                  <NavLink css={navLinkCSS} exact to={`/dashboard/${path}`}>
                    {label}
                  </NavLink>
                </li>
              ))}
              {isNotTeacher && <NotificationCenter currentUser={state.user} />}
              <Dropdown pointing="top right" icon={mobileBarsIcon} size="big">
                <Dropdown.Menu direction="left">
                  <Dropdown.Item
                    icon={
                      <img
                        css={navImageCSS}
                        key="4"
                        alt="User avatar"
                        src={imgDefault}
                      />
                    }
                    text={`${firstname} ${lastname}`}
                  />
                  {RULES[rule].map(({ path, label }) => (
                    <Dropdown.Item
                      key={`item-${path}`}
                      as={NavLink}
                      exact
                      to={`/dashboard/${path}`}
                      text={label}
                    />
                  ))}
                  <Dropdown.Item text="Cerrar sesión" onClick={handleLogout} />
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <li css={navMenuItemCSS} key="logout-menu">
              <Dropdown text={`${firstname} ${lastname}`} pointing>
                <Dropdown.Menu direction="left">
                  <Dropdown.Item onClick={handleLogout}>
                    Cerrar sesión
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
            <li key="user-avatar">
              <img css={navImageCSS} key="4" alt="" src={imgDefault} />
            </li>
          </ul>
        </div>
      ) : (
        <ul css={navListCSS}>
          <li>
            <NavLink css={navMenuItemCSS} exact to="/login">
              Iniciar sesión
            </NavLink>
          </li>
        </ul>
      )}
    </nav>
  );
};

export default NavigationItems;
