import React, { useEffect, createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import reducer from './reducers';
import { USER, LOADING, SHOPPING, LICENSE, NOTIFICATIONS } from './types';
import { useCookies } from 'react-cookie';
import {
  getLocalStorageData,
  addLocalStorageData,
  removeLocalStorageData,
  setLocalStoreData,
} from './utils';
import {
  fetchUsers,
  getCatalogPrice,
  getNotifications,
  setAllNotificationsRead,
} from '../services/api';
import {
  formatNotifications,
  getUpdatedNotifications,
} from './notificationsFormatter.js';

const INIT = {
  user: null,
  loading: true,
  shoppingCart: [],
  license: {},
  notificationData: {
    notifications: [],
    unReadNotifications: 0,
  },
};

const GlobalContext = createContext();

const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INIT);
  const [cookies, setCookie, removeCookie] = useCookies(['auth']);

  const dispatches = {
    fetchUser: async (data) => {
      const user = await fetchUsers(data);
      if (!cookies.auth) {
        await setCookie(
          'auth',
          {
            token: data.token,
            type: data.type,
          },
          {
            path: '/',
          }
        );
      }
      dispatch({ type: USER, payload: user });
    },
    logoutUser: async () => {
      await removeCookie('auth', {
        path: '/',
      });
      dispatch({ type: USER, payload: null });
    },
    updateSingleStudent: (student, index) => {
      const data = Object.assign({}, state.user);
      data.students.splice(index, 1, student);
      dispatch({ type: USER, payload: data });
    },
    updateLicenseStatusByIndex: (studentsIndex) => {
      const user = Object.assign({}, state.user);
      const { students } = user;

      students.map((student, index) => {
        const isPending = studentsIndex.includes(index);

        if (isPending) {
          student.license_status = 'PENDING';
        }
        return student;
      });

      dispatch({ type: USER, payload: user });
    },
    updateStudentListTutor: (student) => {
      const user = Object.assign({}, state.user);
      const studentID = student.id;
      const oldStudentList = user.students;

      const newStudentList = oldStudentList.filter(
        (student) => student.id !== studentID
      );

      newStudentList.push(student);
      user.students = newStudentList;

      dispatch({ type: USER, payload: user });
    },
    updateRelationStatus: (newRelatedUsers) => {
      const user = Object.assign({}, state.user);
      const relatedUsers = user.type === 'student' ? 'tutors' : 'students';
      const oldRelatedUsersList = user[relatedUsers];

      const updatedUsersCodes = newRelatedUsers.map(
        (relatedUser) => relatedUser.public_code
      );

      const updateCurrentUsers = (relatedUser) => {
        const updatedRelatedUser = newRelatedUsers.find(
          (updatedTutor) => updatedTutor.public_code === relatedUser.code
        );
        const newStatus = updatedRelatedUser.status;
        relatedUser.status = newStatus;

        return relatedUser;
      };

      const newRelatedUsersList = oldRelatedUsersList.map((relatedUser) =>
        updatedUsersCodes.includes(relatedUser.code)
          ? updateCurrentUsers(relatedUser)
          : relatedUser
      );

      user[relatedUsers] = newRelatedUsersList;
      dispatch({ type: USER, payload: user });
    },
    addTutorListStudent: (tutor) => {
      const user = Object.assign({}, state.user);
      const tutorID = tutor.id;
      const oldTutorList = user.tutors;

      const newTutorList = oldTutorList.filter((tutor) => tutor.id !== tutorID);

      newTutorList.push(tutor);
      user.tutors = newTutorList;
      dispatch({ type: USER, payload: user });
    },
    updateUser: (data) => dispatch({ type: USER, payload: data }),
    loadingWait: (data) => dispatch({ type: LOADING, payload: data }),
    fetchLicense: async () => {
      let { data, status } = await getCatalogPrice();

      if (status !== 200) {
        //TODO: don’t have a behavior for a failing request. I put the fixed value of the license in the meantime
        data = '45';
      }

      dispatch({ type: LICENSE, payload: Array.isArray(data) ? data[0] : {} });
    },
    changeShoppingListState: (data) =>
      dispatch({ type: SHOPPING, payload: data }),
    addShoppingItem: (key, data) => {
      const newShoppingList = [...state.shoppingCart];
      newShoppingList.push(data);
      addLocalStorageData(key, state.user.code, data);
      dispatch({ type: SHOPPING, payload: newShoppingList });
    },
    deleteShoppingItem: (key, index, newShoppingList) => {
      removeLocalStorageData(key, state.user.code, index);
      dispatch({ type: SHOPPING, payload: newShoppingList });
    },
    cleanShoppingCart: (key, code) => {
      try {
        const localShoppingCart = getLocalStorageData(key) || {};
        localShoppingCart[code] = [];

        setLocalStoreData(key, localShoppingCart);
      } catch {
        return;
      }
      dispatch({ type: SHOPPING, payload: [] });
    },
    restoreShoppingList: (key, code) => {
      try {
        const localStorageData = getLocalStorageData(key) || {};
        let userData = localStorageData[code] ? localStorageData[code] : [];

        if (userData.length) {
          dispatch({ type: SHOPPING, payload: userData });
        }
      } catch {
        return;
      }
    },
    answeredNotification: async () => {
      const { token, provider } = Object.assign({}, state.user);
      const data = await getNotifications(token, provider, true);
      const notificationData = formatNotifications(data);

      dispatch({
        type: NOTIFICATIONS,
        payload: notificationData,
      });
    },
    updateNotificationList: (formattedData, initialLoad) => {
      const currentNotifications = [...state.notificationData.notifications];

      if (initialLoad) {
        const newNotificationData = formattedData;

        dispatch({
          type: NOTIFICATIONS,
          payload: newNotificationData,
        });
      }

      const notificationData = getUpdatedNotifications(
        formattedData,
        currentNotifications
      );

      dispatch({
        type: NOTIFICATIONS,
        payload: notificationData,
      });
    },
    setAllNotificationsRead: async () => {
      const notifications = [...state.notificationData.notifications];
      const { token, provider } = Object.assign({}, state.user);
      await setAllNotificationsRead(token, provider);

      dispatch({
        type: NOTIFICATIONS,
        payload: {
          notifications,
          unReadNotifications: 0,
        },
      });
    },
  };

  useEffect(() => {
    cookies.auth
      ? dispatches.fetchUser(cookies.auth)
      : dispatches.loadingWait(false);
  }, []);

  return (
    <GlobalContext.Provider value={{ state, dispatches }}>
      {children}
    </GlobalContext.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
};

Provider.defaultProps = {
  children: undefined,
};

export { GlobalContext as default, Provider };
