import React, { useContext } from 'react';
import GlobalContext from '../../GlobalContext';
import Card from '../../components/Card';
import GoToProfileCard from './GoToProfileCard';
import MobileAppCard from './MobileAppCard';
import RequiredInfoModal from './RequiredInfoModal';
import {
  informationSectionCSS,
  userHomeContainerCSS,
  welcomeMessageCSS,
} from './style';

const UserHome = () => {
  const {
    state: {
      user: { type, school, phone, country },
    },
  } = useContext(GlobalContext);
  const requiredFields =
    type === 'student' ? [school, phone] : [phone, country];

  return (
    <div css={userHomeContainerCSS}>
      <RequiredInfoModal isShowModal={requiredFields.includes(null)} />
      <Card cardContainerCSS={welcomeMessageCSS}>
        <h1>¡Bienvenido/a a tu cuenta de Okus!</h1>
      </Card>
      <div css={informationSectionCSS}>
        <GoToProfileCard />
        <MobileAppCard />
      </div>
    </div>
  );
};

export default UserHome;
