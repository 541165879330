import { put } from 'axios';
const { REACT_APP_BASE_URL } = process.env;

export const setAllNotificationsRead = async (token, provider) => {
  try {
    const result = await put(
      `${REACT_APP_BASE_URL}/api/v2/${provider}/auth/notifications/mark-all-read`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result;
  } catch (e) {
    return e.response;
  }
};
