import { css } from '@emotion/core';

export const modalItemCSS = css`
  align-items: center;
  border-bottom: 1px solid rgba(16, 86, 52, 0.1);
  display: flex;
  flex-direction: row;
  padding-bottom: 2%;
`;

export const modalItemNameCSS = css`
  color: rgb(16, 86, 52);
  flex-basis: 69%;
  font-size: 1.3em;
  margin: 0;

  @media only screen and (min-device-width: 769px) {
    flex-basis: 70%;
  }

  @media only screen and (max-device-width: 812px) and (orientation: landscape) {
    flex-basis: 67%;
  }
`;

export const modalItemValueCSS = css`
  color: #07a86f;
  flex-basis: 28%;
  font-size: 20px;

  @media only screen and (min-device-width: 769px) {
    flex-basis: 14%;
  }

  @media only screen and (max-device-width: 812px) and (orientation: landscape) {
    flex-basis: 20%;
  }
`;

export const modalItemIconCSS = css`
  &.ui.button {
    background: transparent;
    color: #fc6e5b;
  }
`;
