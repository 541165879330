import { USER, LOADING, SHOPPING, LICENSE, NOTIFICATIONS } from './types';

const reducer = (state, action) => {
  switch (action.type) {
    case USER:
      return {
        ...state,
        user: action.payload,
        loading: false,
      };
    case LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SHOPPING:
      return {
        ...state,
        shoppingCart: action.payload,
      };
    case LICENSE:
      return {
        ...state,
        license: action.payload,
      };
    case NOTIFICATIONS:
      return {
        ...state,
        notificationData: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
