import React, { useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { resetPassword } from '../../services/api';
import {
  resetPasswordCardCSS,
  resetPasswordCardTitleCSS,
  resetPasswordInputCSS,
  resetPasswordButtonCSS,
  resetPasswordLinkCSS,
  resetPasswordContainerCSS,
  resetPasswordTitleCSS,
} from './style';

import Card from '../../components/Card';
import InputContainer from '../../components/InputContainer';

const ResetPassword = () => {
  const [username, setUsername] = useState('');
  const { provider } = useParams();
  const { push } = useHistory();

  const handleSubmit = async () => {
    try {
      const result = await resetPassword(username, provider);

      if (result.status === 200) {
        toast.success(
          'Se le ha enviado un e-mail para restablecer su contraseña.',
          {
            onClose: () => push('/login'),
          }
        );
      }
    } catch (e) {
      toast.error('Este nombre de usuario no es correcto.');
    }
  };

  const inputOptions = {
    inputState: username,
    setInput: setUsername,
    customOptions: {
      name: 'name',
      placeholder: 'Nombre de usuario',
      type: 'text',
    },
    customStyle: resetPasswordInputCSS,
  };

  return (
    <div css={resetPasswordContainerCSS}>
      <div css={resetPasswordTitleCSS}>Restablecer contraseña</div>
      <Card cardContainerCSS={resetPasswordCardCSS}>
        <div css={resetPasswordCardTitleCSS}>Ingrese su nombre de usuario:</div>
        <InputContainer {...inputOptions} />
        <div css={resetPasswordButtonCSS}>
          <button
            onClick={handleSubmit}
            className="ui button"
            disabled={!username}
          >
            Solicitar
          </button>
        </div>
        <Link css={resetPasswordLinkCSS} to="/login">
          &larr; Volver atrás
        </Link>
      </Card>
    </div>
  );
};

export default ResetPassword;
