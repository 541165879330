import React, { useState } from 'react';
import Card from '../../../components/Card';
import { cardContainerCSS, accordionCSS, asociatedUserCardCSS } from './style';
import { Accordion, Message } from 'semantic-ui-react';
import userEmpty from '../../../assets/img/imgDefault.jpg';
import { PropTypes } from 'prop-types';

const ViewAssociatedAccounts = ({ userProfile }) => {
  const [activeAccordion, setActiveAccordion] = useState(true);
  const { provider } = userProfile;

  const linkedsAccount =
    provider === 'tutors' ? userProfile.students : userProfile.tutors;

  const LABELS = { tutors: 'Estudiantes', students: 'Tutores' };
  const providerUpperCase = LABELS[provider];
  const isRelated = (status) => status !== 'disabled';
  const getRelationStatus = (status) =>
    status !== 'enabled' ? 'Solicitud Pendiente' : '';

  const cutTextEithDots = (text, limit) => {
    if (text.length > limit) {
      text = text.substring(0, limit - 3) + '...';
    }

    return text;
  };

  const panels = [
    {
      key: `panel-${userProfile.id}`,
      title: {
        content: <h3>{providerUpperCase}</h3>,
      },
      content: {
        content: (
          <>
            {linkedsAccount &&
              linkedsAccount.map(
                ({ firstname, lastname, status }, index) =>
                  isRelated(status) && (
                    <Message
                      key={index}
                      info
                      content={
                        <div css={asociatedUserCardCSS}>
                          <img src={userEmpty} alt="Foto de usuario" />
                          <div>
                            <h3>{`${cutTextEithDots(
                              firstname,
                              18
                            )} ${cutTextEithDots(lastname, 18)}`}</h3>
                            <p>{`${getRelationStatus(status)}`}</p>
                          </div>
                        </div>
                      }
                    />
                  )
              )}
          </>
        ),
        active: activeAccordion,
      },
    },
  ];

  return (
    <div hidden={!linkedsAccount}>
      <Card cardContainerCSS={cardContainerCSS}>
        {linkedsAccount && linkedsAccount.length > 0 ? (
          <Accordion
            css={accordionCSS}
            defaultActiveIndex={1}
            panels={panels}
            onTitleClick={() => setActiveAccordion(!activeAccordion)}
          />
        ) : (
          <div>No tienes cuentas vinculadas.</div>
        )}
      </Card>
    </div>
  );
};

ViewAssociatedAccounts.propTypes = {
  userProfile: PropTypes.object,
};

ViewAssociatedAccounts.defaultProps = {
  userProfile: {},
};

export default ViewAssociatedAccounts;
