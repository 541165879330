import css from '@emotion/css';

export const cardLayoutCSS = css`
  display: flex;
  justify-content: center;
`;

export const defaultContainerCSS = css`
  background-color: #ffffff;
`;

export const cardTitleCSS = css`
  align-items: center;
  border-bottom: solid 1px #f0f0f0;
  color: rgb(16, 86, 52);
  display: flex;
  font-family: 'Amino Alt Bold';
  flex-direction: row;
  height: 115px;
  justify-content: space-between;
  margin-left: 5%;
  padding: 5% 0% 4% 0%;
  width: 90%;
`;
