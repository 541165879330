import { css } from '@emotion/core';
import { notificationCenterCSS } from './NotificationCenter/style.js';

export const navListCSS = css`
  display: inline-flex;
  align-items: center;
  list-style: none;
  padding: 0;

  & .css-${notificationCenterCSS.name} > .menu.transition {
    width: max-content !important; //Important added here to override semantic-ui style
  }

  @media only screen and (max-width: 767px) {
    & .css-${notificationCenterCSS.name} > .menu.transition {
      width: 250px !important;
    }
  }
`;

export const navListItemCSS = css`
  display: flex;
  padding-right: 25px;
`;

export const navLayout = css`
  display: flex;
  flex: 0 1 50%;
  justify-content: flex-end;
`;

export const navLinkItemCSS = css`
  padding-right: 20px;
`;

export const navLinkActiveCSS = css`
  background: black;
`;

export const navMenuItemCSS = css`
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: white;
  font-weight: 400;
  font-family: Lato;
  padding-right: 14px;
  width: max-content;

  &:focus,
  &:hover {
    outline: none;
    color: white;
  }
`;

export const navLinkCSS = css`
  align-items: center;
  background-color: transparent;
  border: white 1px solid;
  border-radius: 18px;
  color: white;
  display: flex;
  font-size: 16px;
  font-family: 'Proxima Nova Semibold';
  height: 32px;
  justify-content: center;
  min-width: 90px;
  padding: 10px;

  &.active {
    background-color: #fb6e5c;
    border-color: #fb6e5c;
  }

  &:hover {
    color: white;
  }
`;

export const navImageCSS = css`
  border-radius: 100%;
  height: 60px;
  width: 60px;
`;

export const navDeviceCSS = css`
  @media only screen and (min-device-width: 889px) {
    div.dropdown.right {
      display: none;
    }

    ul ~ .css-${notificationCenterCSS.name} {
      display: none;
    }
  }

  @media only screen and (max-device-width: 889px) {
    ul li {
      display: none;
    }
  }
`;
