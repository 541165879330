import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { cardContainerCSS, contentTextCSS, containerCSS } from './style';
import Card from '../../components/Card';
import okusLogo from './img/okus-logo.png';
import { NotMatchPageText } from './messages.json';
import GlobalContext from '../../GlobalContext';

const NotMatchPage = () => {
  const {
    state: { user },
  } = useContext(GlobalContext);

  return (
    <Card cardContainerCSS={cardContainerCSS}>
      <div css={containerCSS}>
        <div css={contentTextCSS}>
          <h1>{NotMatchPageText.header}</h1>
        </div>
        <img alt="okus logo" src={okusLogo} />
        <div css={contentTextCSS}>
          <h2>{NotMatchPageText.content}</h2>
        </div>
        <div css={contentTextCSS}>
          <Link css={contentTextCSS} to={user ? '/dashboard/perfil' : '/login'}>
            {user ? 'Ir al perfil' : 'Iniciar sesión'}
          </Link>
        </div>
      </div>
    </Card>
  );
};

export default NotMatchPage;
