import React from 'react';
import PropTypes from 'prop-types';
import {
  popupContainerCSS,
  validationCSS,
} from '../../assets/styles/styleForm';
import InputPopup from '../InputPopup';

const InputForm = ({
  label,
  type,
  name,
  placeholder,
  validation,
  errorName,
  isInsideModal,
  customStyle,
  children,
  popUpMessage,
}) => {
  return (
    <>
      <label>{label}</label>
      <div css={!isInsideModal && validationCSS}>
        {!children ? (
          <input
            name={name}
            placeholder={placeholder}
            ref={validation}
            type={type}
          />
        ) : (
          children
        )}

        {errorName && (
          <InputPopup
            customStylesCSS={customStyle}
            content={errorName.message ? errorName.message : popUpMessage}
            name={name}
          />
        )}
      </div>
    </>
  );
};

InputForm.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  validation: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  isInsideModal: PropTypes.bool,
  customStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  popUpMessage: PropTypes.string,
  errorName: PropTypes.object,
  children: PropTypes.element,
};

InputForm.defaultProp = {
  label: '',
  name: '',
  validation: () => {},
  placeholder: '',
  type: '',
  isInsideModal: false,
  customStyle: popupContainerCSS,
  popUpMessage: '',
  errorName: {},
  children: null,
};

export default InputForm;
