import { css } from '@emotion/core';

export const payPalLoader = css`
  &.ui.active.loader {
    color: #5f5f5f;
  }

  &.ui.active.loader:before {
    border-color: rgba(0, 0, 0, 0.1);
  }

  &.ui.active.loader:after {
    border-color: #767676 transparent transparent;
  }
`;
