export const LABELS_BY_RELATION = {
  students: 'Ingresa el código de tu tutor',
  tutors: 'Ingresa el código del estudiante',
  studentToTeacher: 'Ingresa el código de tu profesor',
};

export const PROVIDERS = ['students', 'tutors', 'teachers'];

export const PROVIDERS_TYPE_RELATION = {
  students: 'student',
  teachers: 'teacher',
  tutors: 'tutor',
};

export const RELATIONSHIP_REQUEST_ACTIONS = {
  student_to_teacher: 'STUDENT_TEACHER',
  sendNotification: 'SEND_NOTIFICATION',
};

export const SECTIONSWEEKS = {
  '3P': ['W1', 'W2', 'W3', 'W4'],
  '4P': ['W1', 'W2', 'W3', 'W4'],
  '5P': ['W1', 'W2', 'W3', 'W4'],
  '6P': ['W1', 'W2'],
  '1S': ['W1', 'W2'],
  '2S': ['W1', 'W2'],
  '6N': ['W1', 'W2', 'W3', 'W4', 'W5', 'W6', 'W7', 'W8'],
};
