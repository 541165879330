export const BUTTON_STATE = {
  hasLicense: {
    label: 'Licencia comprada',
    disable: true,
  },
  noHasLicense: {
    label: 'Agregar Licencia al carrito',
    disable: false,
  },
  isInShoppingCar: {
    label: 'Licencia agregada',
    disable: true,
  },
  isPendingPurchase: {
    label: 'Activación en proceso',
    disable: true,
  },
};
