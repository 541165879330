import { css } from '@emotion/core';

export const CardContainerCss = css`
  border-radius: 10px;
  border: 1px solid #cce0d6;
  box-shadow: 0 10px 10px 0 rgba(3, 50, 34, 0.04);
  margin: 30px auto 60px;
  max-width: 750px;
  min-width: 320px;
`;

export const FormContainerCss = css`
  padding: 3% 5% 5%;

  label {
    color: rgb(82, 100, 93) !important;
    display: flex;
    justify-content: center;
  }

  .ui .field input {
    border-radius: 8px;
    border: 1px solid rgba(0, 169, 112, 0.5);
    font-size: 16px;
    height: 48px;
    margin: 2px 0;
    padding: 14px 16px;
  }

  .ui.form .field {
    margin-bottom: 24px;
  }

  .ui.form .fields {
    margin-bottom: 4px;
    padding-right: 10px;
  }

  .ui .field label {
    color: rgb(82, 100, 93);
    font-family: 'Proxima Nova Semibold';
    font-size: 16px;
    font-weight: 400;
  }

  .ui.form input:not([type='‘text’']):focus {
    border-color: rgba(0, 169, 112, 0.5);
    border-radius: 8px;
  }

  .two {
    display: flex;
    justify-content: center;
  }
`;

export const FormHeaderCss = css`
  color: rgb(16, 86, 52);
  font-size: 32px;
`;

export const FirstFieldSpaceCss = css`
  margin: 10px 0 40px;

  label.header.ui {
    font-family: 'Proxima Nova Semibold';
    font-size: 16px;
    font-weight: 400;
  }

  div.ui.container div.ui.buttons button {
    font-family: 'Proxima Nova Semibold';
    font-weight: 700;
  }
`;

export const RegisterButtonCss = css`
  background-color: #fb6e5b;
  border-color: #fb6e5b;
  border-radius: 40px;
  border-width: 0px;
  color: #fff;
  font-family: 'Proxima Nova Semibold';
  font-size: 16px;
  font-weight: 400;
  height: 32px;
  margin-top: 2.5rem;
  outline: none;
  text-align: center;
  width: 160px;
`;

export const validationCSS = css`
  position: relative;

  i {
    position: absolute;
    top: 18px;
    right: 10px;
    color: #fbbd08;
    font-size: 20px;
  }

  div[name='section_codes'] {
    border-radius: 8px !important;
    border: 1px solid rgba(0, 169, 112, 0.5) !important;
    font-size: 15px;
    height: 48px;
    margin: 2px 0;
    padding: 14px 16px;

    i {
      color: #ccc;
    }
  }
`;

export const BorderColorValidation = css`
  .buttons {
    border: 2px solid #fbbd08;
  }

  .ui.button:nth-of-type(2) {
    border-left: 2px solid #fbbd08;
    border-right: 2px solid #fbbd08;
  }
`;

export const popupContainerCSS = {
  left: '8px',
  right: '0',
  top: '-7px',
};
