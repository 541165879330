import React from 'react';
import PropTypes from 'prop-types';
import { ProfileInfoCSS, ProfileItemsCSS } from './style';
import getSectionName from '../../../utils/getSectionName';

const ViewContainer = ({ user, studentListed }) => {
  const {
    firstname,
    lastname,
    phone,
    contact,
    username,
    sections = [],
    code,
    type,
  } = user;
  const isStudent = type === 'student';
  const grade = getSectionName(sections);

  return (
    <div>
      <table css={ProfileInfoCSS}>
        <tbody css={ProfileItemsCSS}>
          <tr>
            <td>Nombre:</td>
            <th>{firstname}</th>
          </tr>
          <tr>
            <td>Apellido:</td>
            <th>{lastname}</th>
          </tr>
          <tr>
            <td>Celular:</td>
            <th>{phone}</th>
          </tr>
          <tr>
            <td>Email:</td>
            <th>{contact}</th>
          </tr>
          <tr>
            <td>Nombre de usuario:</td>
            <th>{username}</th>
          </tr>
          {isStudent && (
            <tr>
              <td>Grado:</td>
              <th>{grade}</th>
            </tr>
          )}
          {!studentListed && (
            <tr>
              <td>Código:</td>
              <th>{code}</th>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

ViewContainer.defaultProps = {
  user: {},
  studentListed: null,
};

ViewContainer.propTypes = {
  user: PropTypes.object,
  studentListed: PropTypes.object,
};

export default ViewContainer;
