import { css } from '@emotion/core';

export const asociatedUserCardCSS = css`
  width: 100%;
  display: flex;
  align-items: center;

  h3 {
    margin: 0;
  }

  @media only screen and (max-device-width: 481px) {
    flex-direction: column;
  }
`;

export const accordionCSS = css`
  width: 70%;

  .title {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    border-bottom: 1px black solid;

    h3 {
      margin-right: 60%;
      color: rgb(16, 86, 52);
      margin-top: 0;
    }
  }

  @media only screen and (max-device-width: 481px) {
    h3 {
      margin-right: 0;
    }
  }

  .content > .message {
    .content {
      display: flex;
      align-items: center;

      img {
        border-radius: 100%;
        height: 40px;
        width: 40px;
        margin-right: 10%;
      }

      @media only screen and (max-device-width: 481px) {
        text-align: center;
        h3 {
          margin-top: 5px;
        }

        p {
          margin: 0;
        }

        img {
          margin: 0;
        }
      }
    }
  }
`;

export const cardContainerCSS = css`
  margin-top: 25px;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 10px 10px 0 rgba(3, 50, 34, 0.04);
  display: flex;
  flex-direction: column;
  padding: 22px;
  width: 478px;
  margin-bottom: 50px;

  @media only screen and (max-device-width: 481px) {
    padding: 20px 0 20px;
    width: 350px;
  }
`;
