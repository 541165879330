import {
  validationFirstName,
  validationLastName,
  validationPassword,
  validationEducativeCenter,
  validationEmail,
} from '../../utils/validations';

import { popupContainerCSS } from './style.js';

export default [
  [
    {
      label: 'Nombre del estudiante',
      name: 'firstname',
      type: 'text',
      placeholder: 'Escriba su nombre',
      validation: validationFirstName,
      customStyle: popupContainerCSS,
    },
    {
      label: 'Apellido del estudiante',
      name: 'lastname',
      type: 'text',
      placeholder: 'Escriba su apellido',
      validation: validationLastName,
      customStyle: popupContainerCSS,
    },
  ],
  [
    {
      label: 'Centro Educativo',
      name: 'school',
      type: 'text',
      placeholder: 'Nombre del centro educativo',
      validation: validationEducativeCenter,
      customStyle: popupContainerCSS,
    },
    {
      label: 'Curso / Grado',
      name: 'section_codes',
      type: 'text',
      customStyle: popupContainerCSS,
      hasChildren: true,
      popUpMessage: 'El grado es requerido',
    },
  ],
  [
    {
      label: 'Elige una contraseña para el estudiante',
      name: 'password',
      type: 'password',
      validation: validationPassword,
      customStyle: popupContainerCSS,
    },
    {
      label: 'Confirmar contraseña del estudiante',
      name: 'password_confirmation',
      type: 'password',
      validation: 'Is custom validation',
      customStyle: popupContainerCSS,
    },
  ],
  [
    {
      label: 'Elige un nombre de usuario para el estudiante',
      name: 'email',
      type: 'text',
      validation: validationEmail,
      customStyle: popupContainerCSS,
    },
  ],
];
