import React, { useEffect, useState, useContext } from 'react';
import GlobalContext from '../../../GlobalContext';
import { toast } from 'react-toastify';
import { Button, Form, Select } from 'semantic-ui-react';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { updateProfile } from '../../../services/api';
import { COUNTRIES } from './constant';
import { PropTypes } from 'prop-types';
import {
  customStylesCSS,
  modalItemsCSS,
  popupContainerCSS,
  phoneErrorCSS,
  selectorContainerCSS,
  submitButtonCSS,
  titleContainerCSS,
  titleCSS,
} from './style';
import { validationPhone, validationSchool } from '../../../utils/validations';
import ModalContainer from '../../../components/ModalContainer';
import { sectionsCode } from '../../../services/api/sectionsCode';
import InputForm from '../../../components/InputForm';

const dynamicSelectHandler = (setter) => ({ value }) => setter(value);
const CODE_NACIONAL_TESTS = 'x7kkzh6p';

const RequiredInfoModal = ({ isShowModal }) => {
  const { errors, formState, handleSubmit, register, control } = useForm({
    mode: 'onChange',
  });

  const { state, dispatches } = useContext(GlobalContext);
  const [country, setCountry] = useState(COUNTRIES[15].value);
  const { type, token, provider } = state.user || {};
  const [visibility, setVisibilty] = useState(true);
  const [grades, setGrades] = useState([]);
  const history = useHistory();
  const { containsRequiredFields } = state.user;
  const {
    phone: phoneIsRequired,
    school: schoolIsRequired,
    sections: sectionsIsRequired,
  } = containsRequiredFields;

  const modalOptions = {
    customStylesCSS,
    options: {
      open: visibility,
    },
    title: {
      content: <h2 css={titleCSS}>Completar registro</h2>,
      css: titleContainerCSS,
    },
  };

  useEffect(() => {
    let getGrades = async () => {
      const codes = await sectionsCode();
      setGrades(
        codes.map(({ name: text, code: value }) => ({ text, value })).reverse()
      );
    };
    if (isShowModal) {
      getGrades();
    }
  }, []);

  const onSubmit = async (values) => {
    values.section_codes =
      Boolean(values.section_codes) === true
        ? values.section_codes.split()
        : values.section_codes;

    const result = await updateProfile(provider, values, token);

    if (result.status === 200) {
      toast.success('Actualización de información correcta');
      dispatches.updateUser({ ...result.data, type, token, provider });

      if (values.section_codes === CODE_NACIONAL_TESTS) {
        history.push('home');
      }

      setVisibilty(false);
    } else {
      toast.error('Ha ocurrido un error con el servicio, intenta más tarde.');
    }
  };

  return (
    isShowModal && (
      <ModalContainer {...modalOptions}>
        <Form css={modalItemsCSS} onSubmit={handleSubmit(onSubmit)}>
          {type === 'student' ? (
            <>
              {!sectionsIsRequired && (
                <div css={selectorContainerCSS}>
                  <label>Selecciona tu grado</label>
                  <Controller
                    control={control}
                    as={Select}
                    options={grades}
                    onChange={(event) => event[1].value}
                    name="section_codes"
                    placeholder="Selecciona un grado"
                    rules={{ required: true }}
                  />
                </div>
              )}
              {!schoolIsRequired && (
                <div css={modalItemsCSS}>
                  <InputForm
                    label="Nombre de tu colegio"
                    name="school_key"
                    validation={register(validationSchool)}
                    errorName={errors.school_key}
                    customStyle={popupContainerCSS}
                    isInsideModal={true}
                  />
                </div>
              )}
              {!phoneIsRequired && (
                <div css={[modalItemsCSS, phoneErrorCSS('student')]}>
                  <InputForm
                    label="Número teléfonico"
                    name="phone"
                    validation={register(validationPhone)}
                    errorName={errors.phone}
                    customStyle={popupContainerCSS}
                    isInsideModal={true}
                  />
                </div>
              )}
            </>
          ) : (
            <>
              <label>Número teléfonico</label>
              <div css={[modalItemsCSS, phoneErrorCSS('tutor/teacher')]}>
                <InputForm
                  name="phone"
                  validation={register(validationPhone)}
                  errorName={errors.phone}
                  customStyle={popupContainerCSS}
                  isInsideModal={true}
                />
              </div>
              <div css={selectorContainerCSS}>
                <InputForm
                  label="Selecciona tu país"
                  errorName={errors.country}
                  customStyle={popupContainerCSS}
                  isInsideModal={true}
                >
                  <Select
                    options={COUNTRIES}
                    name="country"
                    onChange={dynamicSelectHandler(setCountry)}
                    placeholder="Selecciona un país"
                    value={country}
                  />
                </InputForm>
              </div>
            </>
          )}
          <div css={submitButtonCSS}>
            <Button
              color="green"
              content="Guardar"
              disabled={!formState.isValid}
              type="submit"
            />
          </div>
        </Form>
      </ModalContainer>
    )
  );
};

RequiredInfoModal.defaultProps = {
  isShowModal: false,
};

RequiredInfoModal.propTypes = {
  isShowModal: PropTypes.bool,
};

export default RequiredInfoModal;
