import { post } from 'axios';
const { REACT_APP_BASE_URL } = process.env;

export const resetPassword = async (username, provider) => {
  // TODO: Move this function to the services directory
  const result = await post(
    `${REACT_APP_BASE_URL}/api/v2/${provider}/password/change/request`,
    {
      username,
    }
  );

  return result;
};
