import { css } from '@emotion/core';
import { informationImageCSS } from '../GoToProfileCard/style';

const buttonsCSS = css`
  .button {
    align-self: center;
    background-color: #01a970;
    border-radius: 7px;
    color: #ffffff;
    font-size: 1.4rem;
    font-weight: 700;
    height: 6rem;
    margin: 1rem 0;
    padding: 0.7em 1.5em 0.7em;
    width: 15rem;
  }
`;

export const installMobileBtnsCSS = css`
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: auto auto;
  justify-items: center;

  .button:not(.icon) > .icon:not(.button):not(.dropdown) {
    align-self: center;
    font-size: 2em;
    margin: 0.5rem 0 0 0;
    opacity: 1;
  }

  ${buttonsCSS}

  .button {
    display: flex;
    text-align: right;
    transition: transform 0.4s ease;

    span {
      font-weight: lighter;
    }

    &:hover {
      background-color: #03b779;
      transform: scale(1.1);
    }
  }

  @media only screen and (max-width: 574px) {
    padding: 0;
    grid-auto-flow: row;
  }

  @media only screen and (max-width: 991px) {
    .button {
      width: 18rem;
    }

    span {
      font-size: 1.5rem;
    }
  }

  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .button {
      width: 14rem;

      span {
        font-size: 1.2rem;
      }
    }
  }

  @media only screen and (min-width: 1200px) {
    .button {
      width: 17rem;
    }
  }
`;

export const mobileAppCSS = css`
  border-radius: 18px;
  box-shadow: 0 10px 10px 0 rgba(3, 50, 34, 0.04);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 2.5%;
  width: 32rem;

  .css-${informationImageCSS.name} {
    padding-top: 3.2rem;
  }

  p {
    margin: 1rem 0;
    width: 80%;
  }

  @media only screen and (max-width: 574px) {
    width: 80vw;
  }

  @media only screen and (max-width: 991px) {
    width: 100%;
  }

  @media only screen and (min-width: 1200px) {
    width: 40rem;
  }
`;
