import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'semantic-ui-react';
import { verifyEmail } from './message.json';
import { PropTypes } from 'prop-types';
import { titleEmailCSS } from './style';

const { Header, Content, Actions } = Modal;
const ModalConfirm = ({ data, onCancel, onSuccess, showModal }) => {
  const [counter, setCounter] = useState(6);

  useEffect(() => {
    if (counter > 0 && showModal) {
      setTimeout(() => {
        setCounter(counter - 1);
      }, 1000);
    }
  }, [counter, showModal]);

  return (
    <Modal open={showModal} size={'tiny'}>
      <Header>{verifyEmail.header}</Header>
      <Content>
        <div>
          {verifyEmail.content.first} <br /> {verifyEmail.content.second} <br />
        </div>

        <div css={titleEmailCSS}>{data.email}</div>
      </Content>
      <Actions>
        <Button onClick={onCancel} negative>
          Editar correo
        </Button>
        <Button
          onClick={() => onSuccess(data)}
          positive={counter <= 0}
          disabled={counter <= 0 ? false : true}
          icon={counter <= 0 ? 'check' : 'wait'}
          labelPosition="right"
          content={
            counter <= 0
              ? 'Confirmar'
              : `Podrás confirmar en ${counter} segundos.`
          }
        />
      </Actions>
    </Modal>
  );
};

ModalConfirm.defaultProps = {
  data: {},
  onCancel: () => {},
  onSuccess: () => {},
  showModal: false,
};

ModalConfirm.propTypes = {
  data: PropTypes.object,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
  showModal: PropTypes.bool,
};

export default ModalConfirm;
