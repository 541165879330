const OPTIONS = {
  name: {
    name: 'name',
    placeholder: 'Escriba su nombre',
    type: 'text',
  },
  lastname: {
    name: 'lastname',
    placeholder: 'Escriba su apellido',
    type: 'text',
  },
  email: {
    name: 'email',
    placeholder: 'Escribe tu correo',
    type: 'text',
  },
  password: {
    name: 'password',
    placeholder: '',
    type: 'password',
  },
};

export default OPTIONS;
