import css from '@emotion/css';

const inputStyleCSS = `
    border: none;
    outline: none;
    padding-left: 20px;
    width: 70%;
`;

const spanStyleCSS = `
    align-self: center;
    color: #05452e;
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    font-size: 18px;

    @media only screen and (max-device-width: 630px) {
        padding-bottom: 10px;
    }
`;

export const infoInputContainerCSS = css`
  align-items: center;
  display: flex;
  border: 1px solid #07a86f;
  border-radius: 18px;
  color: #5c5c5c;
  height: 35px;
  justify-content: space-between;
  margin: 0 20px;
  overflow: hidden;
  width: 250px;

  input {
    ${inputStyleCSS}
  }
  button.ui.button {
    align-items: center;
    background-color: #07a86f;
    border-radius: 0;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-size: 12px;
    height: 100%;
    justify-content: center;
    border-top-right-radius: 25%;
    border-bottom-right-radius: 25%;
    margin-right: -5px;
    width: 67px;
  }
`;

export const containerCSS = css`
  align-self: flex-end;
  display: flex;
  border-radius: 10px;
  box-shadow: 0 10px 10px 0 rgba(3, 50, 34, 0.04);
  margin-top: 20px;
  padding: 25px;
  max-width: 475px;

  span {
    ${spanStyleCSS}
  }

  @media (max-width: 481px) {
    flex-direction: column;
    height: 125px;
    width: 350px;
  }

  @media (max-width: 367px) {
    padding: 0;
    padding-top: 28px;
    width: 292px;
  }
`;
