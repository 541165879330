import React, { useState, useEffect } from 'react';
import { Button, Container } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { centerGroupButtons, groupButtons, customStyle } from './style';
import ENTITIES from './entities';
import { PROVIDERS } from '../../constants';

const { Group } = Button;
const ButtonGroup = ({
  customClass,
  entities,
  setValue,
  customWidthButtons,
  customBackground,
  customColor,
  defaultActive,
}) => {
  const customStyleArr = [customWidthButtons, customBackground, customColor];
  const [activeState, setActive] = useState('');

  useEffect(() => {
    setActive(defaultActive);
    setValue(defaultActive);
  }, [defaultActive, setValue]);

  return (
    <>
      <div css={[centerGroupButtons, customClass]}>
        <Group css={[groupButtons, customStyle(...customStyleArr)]}>
          {entities.map((entity) => (
            <Button
              onClick={() => [setValue(entity), setActive(entity)]}
              key={entity}
              type="button"
              active={activeState === entity}
            >
              {ENTITIES[entity]}
            </Button>
          ))}
        </Group>
      </div>
    </>
  );
};

ButtonGroup.defaultProps = {
  customClass: undefined,
  entities: PROVIDERS,
  setValue: () => {},
  customWidthButtons: '326px',
  customBackground: 'none',
  customColor: 'rgba(0,0,0,.6);',
  defaultActive: '',
};

ButtonGroup.propTypes = {
  customClass: PropTypes.object,
  entities: PropTypes.array,
  setValue: PropTypes.func,
  customWidthButtons: PropTypes.string,
  customBackground: PropTypes.string,
  customColor: PropTypes.string,
  defaultActive: PropTypes.string,
};

export default ButtonGroup;
