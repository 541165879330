import React, { useState, useContext } from 'react';
import { Message, Header } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import GlobalContext from '../../GlobalContext';
import { sendValidationMail } from '../../services/api';
import {
  comfirmMessageCSS,
  confirmMessageDetailCSS,
  confirmButtonContainerCSS,
  confirmButtonTextCSS,
  confirmButtonMailCSS,
  confirmMessageBottomCSS,
} from './style';

import ChangeMailModal from './ChangeMailModal';

const ConfirmMailMessage = () => {
  const [openModal, setOpenModal] = useState(false);
  const { state } = useContext(GlobalContext);
  const { contact, token, provider, has_social } = state.user;

  const handleResent = async () => {
    const result = await sendValidationMail(token, provider);

    if (result) {
      toast.success('Operación exitosa.');
    } else {
      toast.error('Ha ocurrido un error con el servicio, intenta más tarde.');
    }
  };

  const handleModal = () => {
    setOpenModal(true);
  };

  // TODO: Need to re-check the resend mail button structure
  return (
    <Message css={comfirmMessageCSS} size="big" color="orange">
      <Header as="h2">Tu correo aún no ha sido validado.</Header>
      <p css={confirmMessageDetailCSS}>
        Presiona el botón para recibir un nuevo correo de validación y sigue las
        instrucciones en dicho correo.
      </p>
      <div css={confirmButtonContainerCSS}>
        <button onClick={handleResent} className="ui button">
          <span css={confirmButtonTextCSS}>
            Reenviar correo de confirmación
          </span>
          <span css={confirmButtonMailCSS}>{contact}</span>
        </button>
      </div>
      {!has_social && (
        <p css={confirmMessageBottomCSS}>
          Puedes cambiar tu dirección de correo electrónico{' '}
          <button type="button" onClick={handleModal}>
            aquí
          </button>
          .
        </p>
      )}
      <ChangeMailModal open={openModal} setOpen={setOpenModal} />
    </Message>
  );
};

export default ConfirmMailMessage;
