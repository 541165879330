import css from '@emotion/css';

const buttonsCSS = css`
  span {
    align-self: center;
    background-color: #01a970;
    border-radius: 7px;
    color: #ffffff;
    font-size: 1.4rem;
    font-weight: 700;
    margin: 1rem 0;
    min-height: 1rem;
    padding: 0.7em 1.5em 0.7em;
    text-align: center;
    width: 15rem;
  }
`;

export const informationImageCSS = css`
  align-self: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  overflow: hidden;
  transition: transform 0.4s ease;

  img {
    align-self: center;
    box-shadow: 0 10px 10px 0 rgba(3, 50, 34, 0.11);
    border-radius: 50%;
    height: 300px;
    margin-bottom: 1rem;
    object-fit: cover;
    width: 300px;
  }

  @media only screen and (max-width: 574px) {
    img {
      height: 200px;
      width: 200px;
    }
  }
`;

export const goToProfileCSS = css`
  border-radius: 18px;
  box-shadow: 0 10px 10px 0 rgba(3, 50, 34, 0.04);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 5.5%;
  text-align: justify;
  width: 32rem;

  ${buttonsCSS}

  .css-${informationImageCSS.name} {
    cursor: pointer;
    
    &:hover {
      transform: scale(1.1);
    }
  }

  p {
    margin-bottom: 2rem;
  }

  @media only screen and (max-width: 574px) {
    width: 80vw;
  }

  @media only screen and (max-width: 991px) {
    width: 100%;
  }

  @media only screen and (min-width: 1200px) {
    width: 40rem;
  }
`;
