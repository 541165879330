import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Dimmer, Loader } from 'semantic-ui-react';
import { paymentStart } from '../../../../services/api/paymentStart';
import { paymentRegister } from '../../../../services/api/paymentRegister';
import { toast } from 'react-toastify';
import { ERRORS } from './constantErrors';
import { payPalLoader } from './style';

const ShoppingCartPayPalButton = ({
  shoppingCart,
  licenses,
  currentUser,
  onPaymentFinish,
  enableModalClose,
}) => {
  const [loader, setLoader] = useState(false);
  const loaderRef = useRef(loader);
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);
  let payLoad = [];

  const payPalButtonConfig = () =>
    window.paypal.Buttons({
      style: {
        shape: 'rect',
        color: 'blue',
        label: 'buynow',
      },
      onInit: function (data, actions) {
        if (!shoppingCart.length) actions.disable();
      },
      createOrder: async function (data, actions) {
        const dataList = shoppingCart.map(({ index }) => {
          const { code } = currentUser.students[index];
          return { user_code: code, license_code: licenses.code };
        });

        payLoad = await paymentStart(dataList, currentUser.token);
        const { status } = payLoad;

        ERRORS[status] && toast.error(ERRORS[status]);

        if (status !== 200 && !ERRORS[status]) {
          toast.error('Ocurrió un problema procesando su solicitud');
        }

        const { value, label } = payLoad.data.amount;

        return actions.order.create({
          purchase_units: [
            {
              amount: {
                label,
                value,
              },
            },
          ],
        });
      },
      onApprove: function (data, actions) {
        setIsLoadingRequest(true);
        return actions.order.capture().then(async function (details) {
          const { status } = await paymentRegister(
            details,
            payLoad.data.token,
            currentUser.token
          );

          setIsLoadingRequest(false);

          if (status === 200) {
            toast.success(
              `Transacción realizada por ${details.payer.name.given_name}!.
               Activando licencia, tomará unos minutos.`
            );
            onPaymentFinish();
          }
        });
      },
    });

  useEffect(() => {
    const button = payPalButtonConfig();
    const time = loaderRef.current ? 500 : 0;
    const timer = setTimeout(() => {
      enableModalClose(false);
      // TODO: Fix reference issue with the loader state
      setLoader(() => (loaderRef.current = false));
      button.render('#paypal-button-container').then(() => {
        enableModalClose(true);
      });
    }, time);

    return () => {
      // TODO: Fix reference issue with the loader state
      setLoader(() => (loaderRef.current = true));
      button.close();
      clearTimeout(timer);
    };
  }, [shoppingCart.length]);

  return (
    <>
      <Dimmer active={isLoadingRequest} page>
        <Loader>Cargando</Loader>
      </Dimmer>
      <div id="paypal-button-container">
        {loader && (
          <Loader css={payPalLoader} active size="large" inline="centered">
            Calculando el monto total
          </Loader>
        )}
      </div>
    </>
  );
};

ShoppingCartPayPalButton.propTypes = {
  shoppingCart: PropTypes.array,
  licenses: PropTypes.object,
  currentUser: PropTypes.object,
  onPaymentFinish: PropTypes.func,
  enableModalClose: PropTypes.func,
};

ShoppingCartPayPalButton.defaultProps = {
  shoppingCart: [],
  licenses: {},
  currentUser: {},
  onPaymentFinish: () => {},
  enableModalClose: () => {},
};

export default ShoppingCartPayPalButton;
